import { useContext, useRef, useState } from "react"
import { FieldState, ManagementCompany, Organization } from "../../Interfaces"
import Header from "../../components/forms/Header"
import { addOrganizationFields } from "../../constants/formFields"
import Input from "../../components/forms/Input"
import ImageDragDrop from "../../components/forms/ImageDragDrop"
import { organizationService } from "../../services/organizationService"
import { cleanPhoneNumber, phoneNumberAutoFormat } from "../../utils/helper"
import { managementCompanyService } from "../../services/managementCompanyService"
import { useQuery } from "@tanstack/react-query"
import useUser from "../../hooks/useUser"
import CustomSelect from "../../components/forms/CustomSelect"
import { SingleValue } from "react-select"
import { useNavigate } from "react-router-dom"
import SaveButtonWithStatus from "../../components/general/SaveButtonWithStatus"
import { COMPANY_MANAGER, OWNER } from "../../utils/roles"
import DataContext from "../../context/dataProvider"

const fields = addOrganizationFields
let fieldsState: FieldState = {}
fields.forEach((field) => (fieldsState[field.name] = ""))

export default function AddOrganization() {
  const { id, roleId } = useUser()
  const { currentUser } = useContext(DataContext)
  const [addOrganizationFormState, setAddOrganizationFormState] = useState(fieldsState)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [saveStatus, setSaveStatus] = useState<string | null>(null)

  const { data: managementCompanyList } = useQuery(["managementCompanies"], () => managementCompanyService.getAll(id), { enabled: roleId && roleId > COMPANY_MANAGER ? true : false })

  let managementCompanyId = useRef<number | null>(null)
  const navigate = useNavigate()

  const handleInputChange = (e: { target: { name: string; value: string } }) => {
    if (e.target.name === "phone") {
      const phoneNumber = phoneNumberAutoFormat(e.target.value)
      setAddOrganizationFormState({ ...addOrganizationFormState, [e.target.name]: phoneNumber })
    } else {
      setAddOrganizationFormState({ ...addOrganizationFormState, [e.target.name]: e.target.value })
    }
  }

  const handleManagementCompanySelect = (managementCompany: SingleValue<ManagementCompany>) => {
    if (managementCompany) {
      managementCompanyId.current = managementCompany.id
    }
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSaveStatus("Saving...")
    if(!managementCompanyId.current){
      return alert("Please select a management company")
    }
    // add company logo to addOrganizationFormState
    const newOrganizationInfo = { ...addOrganizationFormState, managementCompanyId: managementCompanyId.current || currentUser.managementCompanyId }
    const organization: Organization | null = await organizationService.create(newOrganizationInfo, imageFile)
    // clear form
    // setAddOrganizationFormState(fieldsState)
    if (organization) {
      setSaveStatus("Saved!")
      setTimeout(() => {
        setSaveStatus(null)
        if (organization) navigate(`/app/organizations/${organization.id}`, { state: { currentItem: organization } })
      }, 1000)
    } else {
      setSaveStatus("Error")
      setTimeout(() => {
        setSaveStatus(null)
      }, 1500)
    }
  }

  return (
    <div className="w-full min-h-full flex justify-center">
      <section className="p-5 w-full lg:max-w-4xl md:p-5 md:shadow-xl md:rounded-xl bg-white max-h-screen overflow-auto">
        <Header heading="Add Area" paragraph="Areas are required to create locations. Each area can contain one or more locations."/>
        <form className="mt-8 space-y-2" onSubmit={handleSubmit}>
          <div className="lg:flex lg:justify-evenly items-center">
            {roleId && roleId > OWNER ? (
              <section className="lg:w-full">
                {managementCompanyList?.length ? <CustomSelect list={managementCompanyList} placeholder="Select Management Company" handleSelection={handleManagementCompanySelect} required={true} /> : null}
                <div>
                  {fields.map((field) => (
                    <Input
                      className="bg-gray-50 border-gray-300"
                      key={field.id}
                      handleChange={handleInputChange}
                      value={addOrganizationFormState[field.id]}
                      labelText={field.labelText}
                      labelFor={field.labelFor}
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      isRequired={field.isRequired}
                      placeholder={field.placeholder}
                    />
                  ))}
                </div>
                <div className="w-full"></div>
              </section>
            ) : (
              <h1 className="text-center text-2xl">You do not have permission to add an area</h1>
            )}
            <section className="lg:w-full lg:pl-5 lg:m-auto">
              <ImageDragDrop setImageState={setImageFile} title="Upload Logo" />
            </section>
          </div>
          <SaveButtonWithStatus saveStatus={saveStatus} handleSave={handleSubmit} isForm={true} className="w-full" />
        </form>
      </section>
    </div>
  )
}
