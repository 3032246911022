import { GoogleMap, InfoWindowF, Libraries, MarkerF, PolylineF, useJsApiLoader } from "@react-google-maps/api"
import shieldIcon from "../../assets/site-walk-shield-only.png"
import noteIcon from "../../assets/note.png"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { MarkerImage, MarkerLocation, WalkNote } from "../../Interfaces"
import cameraIcon from "../../assets/camera.png"
import blueMarker from "../../assets/waypoint-blue.png"
import purpleMarker from "../../assets/waypoint-purple.png"

interface MapProps {
  name: string
  zoom: number
  setZoom: Dispatch<SetStateAction<number>>
  coordinates: google.maps.LatLngLiteral | google.maps.LatLng
  handleLocationAdjustment?: Function
  setMarkers?: (markers: MarkerLocation[]) => void
  markers?: MarkerLocation[]
  walkNotes?: WalkNote[]
  walkImages?: MarkerImage[]
  handleNoteClick?: (notes: WalkNote[]) => void
  handleImageClick?: (images: MarkerImage[]) => void
  animate?: boolean
}

const libraries: Libraries = ["geometry"]

export default function Map({ name, zoom, setZoom, coordinates, handleLocationAdjustment, setMarkers, markers, walkNotes, walkImages, handleNoteClick, handleImageClick, animate = false }: MapProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!!,
    libraries,
  })
  const [map, setMap] = useState<google.maps.Map>()
  const [position, setPosition] = useState<google.maps.LatLngLiteral | google.maps.LatLng>(coordinates || { lat: 0, lng: 0 })
  const [path, setPath] = useState(markers?.map((marker) => ({ lat: marker.lat, lng: marker.lng })))
  const [offset, setOffset] = useState(0)
  const [condensedWalkNotes, setCondensedWalkNotes] = useState<WalkNote[][]>([])
  const [consdensedWalkImages, setCondensedWalkImages] = useState<MarkerImage[][]>([])

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset((oldOffset) => (oldOffset + 0.5) % 500) // Adjust the % value for speed and reset
    }, 100) // Adjust the interval for speed
    if (!animate) clearInterval(interval)

    return () => clearInterval(interval)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPosition(coordinates || { lat: 0, lng: 0 })
  }, [coordinates])

  useEffect(() => {
    if (!isLoaded) return
    if (!walkNotes) return
    if (!walkNotes.length) return
    // Change walkNotes.note to walkNotes.notes. This will be an array of strings instead of a single string and will be used when the lat and lng are close to each other

    const condensedWalkNotes: WalkNote[][] = []
    walkNotes.forEach((note) => {
      const indexMap = condensedWalkNotes.map((notesArray) =>
        notesArray.findIndex((condensedNote): Boolean => {
          // if the distance between the two notes is less than 15 meters, add the note to the notes array of the condensed note
          return google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(condensedNote.lat, condensedNote.lng), new google.maps.LatLng(note.lat, note.lng)) < 15
        })
      )
      const index = indexMap.findIndex((i) => i > -1)
      if (index > -1) {
        condensedWalkNotes[index].push(note)
      } else {
        condensedWalkNotes.push([note])
      }
    })

    setCondensedWalkNotes(condensedWalkNotes || [])
  }, [walkNotes, isLoaded])

  useEffect(() => {
    // If the Google Maps API is not loaded, exit the function
    if(!isLoaded) return
    // If walkImages is not defined or is an empty array, exit the function
    if (!walkImages || !walkImages.length) return
  
    // Initialize an empty array to hold arrays of MarkerImage objects
    const condensedWalkImages: MarkerImage[][] = []
  
    // Iterate over each image in walkImages
    walkImages.forEach((image) => {
      // For each image, create an array (indexMap) where each element is the index of the first image in the corresponding condensedWalkImages array that is within 15 meters of the current image
      const indexMap = condensedWalkImages.map((imagesArray) =>
        imagesArray.findIndex((condensedImage): Boolean => {
          // Use Google Maps API to calculate the distance between the current image and the condensed image
          return google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(condensedImage.lat, condensedImage.lng), new google.maps.LatLng(image.lat, image.lng)) < 15
        })
      )
  
      // Find the first array in condensedWalkImages that contains an image within 15 meters of the current image
      const index = indexMap.findIndex((i) => i > -1)
  
      // If such an array is found, add the current image to that array
      if (index > -1) {
        condensedWalkImages[index].push(image)
      } else {
        // If no such array is found, create a new array containing only the current image and add it to condensedWalkImages
        condensedWalkImages.push([image])
      }
    })
  
    // Update the state with the new condensedWalkImages array
    setCondensedWalkImages(condensedWalkImages || [])
  }, [walkImages, isLoaded])

  useEffect(() => {
    setPath(markers?.map((marker) => ({ lat: marker.lat, lng: marker.lng })))
  }, [markers])

  const containerStyle = {
    width: "100%",
    height: "100%",
  }

  const zoomLevel = zoom || 6

  const handleMapDoubleClick = (e: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
    e.domEvent.preventDefault()
    if (!setMarkers) return
    setMarkers([
      {
        lat: e.latLng?.lat() || 0,
        lng: e.latLng?.lng() || 0,
        label: markers?.length ? markers.length + 1 : 1,
      },
    ])
  }

  function onMarkerDragEnd(markerLabel: number, e: google.maps.MapMouseEvent | google.maps.IconMouseEvent) {
    markers?.forEach((marker) => {
      if (marker.label === markerLabel) {
        marker.lat = e.latLng?.lat() || 0
        marker.lng = e.latLng?.lng() || 0
      }
      setPath(markers?.map((marker) => ({ lat: marker.lat, lng: marker.lng })))
    })
  }

  const getArrowSymbol = () => ({
    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 2,
    strokeColor: "#8e44ad",
  })

  let polylineOptions = isLoaded
    ? {
        path: path,
        icons: [
          {
            icon: getArrowSymbol(),
            offset: `${offset / 20}%`, // Adjust this for animation speed. Increase the number to slow down the animation
            repeat: "62px", // Adjust this for more or fewer arrows
          },
        ],
        strokeColor: "#3498db",
        strokeOpacity: 0.8,
        strokeWeight: 0.5,
      }
    : {}

  if (isLoaded) {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={zoomLevel}
        onLoad={(map) => setMap(map)}
        onDblClick={handleMapDoubleClick}
        onZoomChanged={() => {
          const zoom = map?.getZoom() || zoomLevel
          setZoom(zoom)
        }}
        onCenterChanged={() => {
          if (handleLocationAdjustment === undefined) return
          const lat = Number(map?.getCenter()?.lat()) || Number(position.lat)
          const lng = Number(map?.getCenter()?.lng()) || Number(position.lng)
          handleLocationAdjustment({ lat, lng })
        }}
        options={{
          disableDoubleClickZoom: true,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
          ],
        }}
      >
        <PolylineF options={polylineOptions} />
        {zoom > 10 && name && (
          <MarkerF
            position={position || { lat: 0, lng: 0 }}
            icon={{
              url: shieldIcon,
              // You might want to set the scaledSize to maintain icon size
              scaledSize: new google.maps.Size(20, 20),
            }}
          >
            <InfoWindowF position={position}>
              <p>{name}</p>
            </InfoWindowF>
          </MarkerF>
        )}

        {markers?.map((marker, i) => {
          const markerIcon = marker.notes?.length || marker.images?.length ? blueMarker : blueMarker

          if (!marker.lat || !marker.lng) {
            return null
          }
          return (
            <div key={marker.id}>
              <MarkerF
                key={i + "circle"}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={{
                  url: markerIcon,
                  anchor: new google.maps.Point(15, 15),
                }}
                draggable={marker.createdAt ? false : true}
                onDragEnd={(e) => onMarkerDragEnd(marker.label!, e)}
                label={{
                  text: marker.label.toString(),
                  fontSize: "14px",
                  color: "white",
                }}
                zIndex={10}
              />
            </div>
          )
        })}
        {condensedWalkNotes?.map((notesArray, i) => {
          const note = notesArray[0]
          return (
            <MarkerF
              key={i}
              position={{ lat: note.lat, lng: note.lng }}
              icon={{
                url: noteIcon,
                scaledSize: new google.maps.Size(25, 25),
              }}
              zIndex={20}
              onClick={() => handleNoteClick && handleNoteClick(notesArray)}
            />
          )
        })}
        {consdensedWalkImages?.map((imagesArray, i) => {
          const image = imagesArray[0]
          return (
            <MarkerF
              key={i}
              position={{ lat: image.lat, lng: image.lng }}
              icon={{
                url: cameraIcon,
                scaledSize: new google.maps.Size(20, 20),
              }}
              zIndex={30}
              onClick={() => handleImageClick && handleImageClick(imagesArray)}
            />
          )
        })}
      </GoogleMap>
    )
  } else {
    return (
      <div className="flex w-full justify-center items-center">
        <h1>Loading Map...</h1>
      </div>
    )
  }
}
