import axios from "axios";
import { Location } from "../Interfaces";
import { showErroPage, sortByAttribute } from "../utils/helper";

class LocationService {

  private baseUrl = process.env.REACT_APP_API_URL + "/location";

  public create = async (locationInfo: any) => {
    if (!locationInfo) throw new Error("No location info provided")
    try {
      const response = await axios.post(
        `${this.baseUrl}`,
        locationInfo,
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public getOne = async (id: number) => {
    if (!id) throw new Error("No id provided")
    try {
      const response = await axios.get(
        `${this.baseUrl}/${id}`,
         { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
    }
  }

  public getOrganizationName = async (id: number) => {
    if (!id) throw new Error("No id provided")
    try {
      const response = await axios.get(
        `${this.baseUrl}/organization-name/${id}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
    }
  }

  public getManagementCompanyName = async (id: number) => {
    if (!id) throw new Error("No id provided")
    try {
      const response = await axios.get(
        `${this.baseUrl}/management-company-name/${id}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
    }
  }

  public getAllByUser = async (userId: number):Promise<Partial<Location[]>> => {
    //TODO: this route will will check if user has permissions on the backend
    try {
      const response = await axios.get(
        `${this.baseUrl}/all/by-user/${userId}`
      )
      sortByAttribute(response.data, "name")
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response?.data)
        throw Error(error.response?.statusText)
      } else {
        console.error(error)
        showErroPage()
        return []
      }
    }
  }

  public getSomeByUser = async (userId: number, limit: number, page: number, searchTerm?: string) /*partial location[] and number*/: Promise<{ locations:Location[], totalCount: number}>  => {
    //TODO: this route will will check if user has permissions on the backend
    try {
      const response = await axios.get(
        `${this.baseUrl}/some/by-user/${userId}?limit=${limit}&offset=${page}&searchTerm=${searchTerm || ""}`
      )
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response?.data)
        throw Error(error.response?.statusText)
      } else {
        console.error(error)
        showErroPage()
        return [] as any
      }
    }
  }

  public getAllByOrganization = async (organizationId: number) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/all/by-organization/${organizationId}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response?.data);
        throw Error(error.response?.statusText)
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }
  public getSomeByOrganization = async (organizationId: number, limit: number, page: number, searchTerm?: string) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/some/by-organization/${organizationId}?limit=${limit}&offset=${page}&searchTerm=${searchTerm || ""}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response?.data);
        throw Error(error.response?.statusText)
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public update = async (locationInfo: Location) => {
    try {
      const response = await axios.put(
        `${this.baseUrl}`,
        locationInfo
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
      return null
    }
  }

  public delete = async (id: number) => {
    if (!id) throw new Error("No id provided")
    try {
      const response = await axios.delete(
        `${this.baseUrl}/${id}`
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }
}

export const locationService = new LocationService()