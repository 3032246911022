import React, { useState } from 'react'
import storageWalkImage from "../../assets/storage_walk.jpeg"
import storageWalkLogo from "../../logo/blue_and_orange_storage_walk_logo.png"

const Hero = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleEmailClick = () => {
    setShowPopup(true);
  }

  const handleClosePopup = () => {
    setShowPopup(false);
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${storageWalkImage})`,
    height: '80vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }

  return (
    <div className="w-full mx-auto py-20 px-6 flex justify-center" style={backgroundImageStyle}>
      <div className="flex flex-col items-center mt-6 lg:mt-2 justify-center">
        <img src={storageWalkLogo} alt="StorageWalk Logo" className="xs:w-72 md:w-80 xs:h-48 md:h-56 lg:w-96 lg:h-64"/>
        <div className='text-white'>
          WALK, TRACK, REPORT—YOUR SITE, SECURE
        </div>
        <button onClick={handleEmailClick} style={{backgroundColor: "#FF5C36"}} className="py-3 px-12 my-14 text-white font-bold rounded-md">
          Get Started Today
        </button>
      </div>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={handleClosePopup}>
          <div className="bg-white p-6 rounded-md shadow-lg xs:w-3/4 md:w-1/2" onClick={(e) => e.stopPropagation()}>
              <p className="text-center">Email Jim at <a href="mailto:jdaniels@aura-realestate.com" className="text-blue-600 hover:underline">jdaniels@aura-realestate.com</a> for more information</p>
            </div>
        </div>
      )}
    </div>
  )
}

export default Hero