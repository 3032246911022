import { FormEventHandler } from "react";

interface FormActionProps {
  handleSubmit: FormEventHandler<HTMLButtonElement>
  type?: string;
  action?: "submit" | "button" | "reset" | undefined
  text: string;
}

export default function FormAction({
  handleSubmit,
  type='Button',
  action='submit',
  text
}: FormActionProps){
  return(
      <>
      {
          type==='Button' ?
          <button
              type={action}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mt-10"
              onClick={handleSubmit}
          >

              {text}
          </button>
          :
          <></>
      }
      </>
  )
}