
import SaveButtonWithStatus from "../general/SaveButtonWithStatus"

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: (e: {    preventDefault: () => void;}) => void
  saveStatus?: string | null;
}

export default function ConfirmationModal ({ isOpen, title, message, onConfirm, onCancel, saveStatus }: ConfirmationModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed z-50 top-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded shadow-lg p-6 w-96">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <p className="mb-4">{message}</p>
        <div className="flex justify-end space-x-2">
          <button 
            onClick={onCancel} 
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:shadow-right-lg transform active:translate-y-1 active:scale-[.99]  active:shadow-none font-medium"
          >
            Cancel
          </button>
          <SaveButtonWithStatus
            text="Confirm"
            saveStatus={saveStatus || null}
            handleSave={onConfirm}
          />
   
        </div>
      </div>
    </div>
  );
};

