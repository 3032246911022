export default function validateInput(
  input: {
    validity: any
    setCustomValidity(arg0: string): unknown
    name: string
    value: string
  },
  errorMessage: string
) {
  if (input.validity.patternMismatch) {
    return input.setCustomValidity(errorMessage)
  } else {
    return input.setCustomValidity("")
  }
}
