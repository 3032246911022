import Header from "../../../components/forms/Header"
import ConfirmNewPassword from "../../../components/login/confirmNewPassword"


export default function ConfirmNewPasswordPage() {
  return (
    <section className="flex pt-6 md:pt-none md:items-center md:pb-44 justify-center md:w-full md:bg-gradient-to-r from-primary via-secondary to-secondary-light">
    <div className="md:w-2/4 lg:max-w-xl md:p-5 md:shadow-xl md:rounded-xl bg-white">
        <Header heading="Create New Password" linkName="New Password" linkUrl="/" />
        <h4 className="text-center">Welcome back!</h4>
        <p className="text-center text-sm text-gray-600 mt-5">Enter your new password. Password must be at least 6 characters."</p>
        <ConfirmNewPassword />
      </div>
    </section>
  )
}