import { useEffect, useRef, useState } from "react"
import { FaCircleXmark, FaUpload } from "react-icons/fa6"

interface UploadFileProps {
  setImageState: (file: File | null) => void
  title?: string
  className?: string
  activateUpload?: boolean
}

export default function ImageDragDrop({ setImageState: setParentImageState, title = "Upload File", className = "", activateUpload = false }: UploadFileProps) {
  const [error, setError] = useState<string>("")
  const iconRef = useRef<HTMLInputElement>(null!)
  const [preview, setPreview] = useState("")

  useEffect(() => {
    if (activateUpload) {
      iconRef.current.click()
    }
  }, [activateUpload])

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    handleFile(file)
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    handleFile(file)
  }

  const handleFile = (file: File | null) => {
    const valid = validateFile(file)
    if (!valid) return
    setPreview(URL.createObjectURL(file!))
    setParentImageState(file)
  }

  const handleRejectFile = () => {
    setPreview("")
    setParentImageState(null)
  }

  const validateFile = (file: File | null) => {
    if (file) {
      if (!file.type.startsWith("image/")) {
        setError("Please select an image file")
        return false
      } else if (file.size > 1000000) {
        setError("File size is too large")
        return false
      } else {
        setError("")
        return true
      }
    }
    return false
  }

  return (
    <div className={className}>
      {" "}
      <h3 className="pb-1">{title}</h3>{" "}

      <div className="w-full h-32 md:h-60 border-2 border-dashed border-primary-light rounded-lg bg-gray-50 flex flex-col justify-center items-center" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
        <input ref={iconRef} className="file-input" type="file" accept=".png, .jpeg, .jpg, .svg, .heic" onChange={handleFileUpload} name="file" hidden />

        {preview ? (
          <button className="relative" onClick={handleRejectFile}>
            <div className="rounded-full w-6 h-6 bg-white -top-2 -right-2 absolute"></div>
            <FaCircleXmark className="text-2xl text-red-600 bg-red -top-2 -right-2 absolute" />
            <img src={preview} width={200} className="rounded-lg z-0" alt="Profile or Logo" />
          </button>
        ) : (
          <>
            <FaUpload className="text-3xl text-primary cursor-pointer" onClick={() => iconRef.current.click()} />
            <p>Or</p>
            <p>Drag and Drop to Upload</p>
          </>
        )}
        {error}
      </div>
    </div>
  )
}
