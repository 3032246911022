import { createContext, useState } from "react"
import { Children } from "../Interfaces"

interface UiContextProps {
  sidebarOpen: Boolean
  deviceView: number
  setSidebarOpen: React.Dispatch<React.SetStateAction<Boolean>>
  setDeviceView: React.Dispatch<React.SetStateAction<number>>
}

const UiContext = createContext<UiContextProps>({ sidebarOpen: true, deviceView: 0, setSidebarOpen: () => { }, setDeviceView: () => { }})

export const UiProvider = ({ children }: Children) => {
  const [sidebarOpen, setSidebarOpen] = useState<Boolean>(true)
  const [deviceView, setDeviceView] = useState(0)

  return <UiContext.Provider value={{ sidebarOpen, setSidebarOpen, deviceView, setDeviceView }}>{children}</UiContext.Provider>
}

export default UiContext
