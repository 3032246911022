import { useRef, useState } from "react"
import { FieldState, Location, Organization } from "../../Interfaces"
import Header from "../../components/forms/Header"
import Input from "../../components/forms/Input"
import { addLocationFields } from "../../constants/formFields"
import { locationService } from "../../services/locationService"
import CustomSelect from "../../components/forms/CustomSelect"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { organizationService } from "../../services/organizationService"
import useUser from "../../hooks/useUser"
import Map from "../../components/maps/Map"
import { getCoordinates } from "../../utils/helper"
import { useNavigate } from "react-router-dom"
import SaveButtonWithStatus from "../../components/general/SaveButtonWithStatus"
import { GiCrosshair } from "react-icons/gi"

const fields = addLocationFields
let fieldsState: FieldState = {}
fields.forEach((field) => (fieldsState[field.name] = ""))

export default function AddLocation() {
  const { id } = useUser()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: organizationList } = useQuery(["organizations"], () => organizationService.getAllByUser(id))
  const [addLocationFormState, setAddLocationFormState] = useState(fieldsState)
  const [organizationId, setOrganizationId] = useState<number>()
  const [location, setLocation] = useState<google.maps.LatLng | google.maps.LatLngLiteral>({ lat: 36.7783, lng: -119.4179 })
  const [zoom, setZoom] = useState<number>(0)
  const [name, setName] = useState<string>("")
  const [saveStatus, setSaveStatus] = useState<string | null>(null)
  const managementCompanyId = useRef<undefined | number>(undefined)


  

  // latitude and longitude are set as ref so that they can be accessed in the handleSubmit function but don't cause re-renders or change on re-render. Used to allow user to customize exact location of marker after initial address search
  const latitude = useRef<number>(0)
  const longitude = useRef<number>(0)

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setAddLocationFormState((previousState) => ({ ...previousState, [e.target.name]: e.target.value }))
  }

  const handleOrganizationSelect = (organization: Organization) => {
    managementCompanyId.current = organization.managementCompanyId
    setOrganizationId(organization.id)
  }

  const handleSearch = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const { lat, lng } = await getCoordinates(`${addLocationFormState.address}+${addLocationFormState.city}+${addLocationFormState.state}+${addLocationFormState.zipcode}`)
    setZoom(18)
    setLocation({ lat, lng })
    setName(addLocationFormState.name)
  }

  const handleLocation = (latLng: google.maps.LatLng | google.maps.LatLngLiteral) => {
    latitude.current = Number(latLng.lat)
    longitude.current = Number(latLng.lng)
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    queryClient.invalidateQueries(["locations"])
    const isValid = validateForm()
    if (isValid) {
      setSaveStatus("Saving...")
      // send to backend through service layer
      const location: Location | null = await locationService.create({ ...addLocationFormState, managementCompanyId: managementCompanyId.current, organizationId, latitude: latitude.current, longitude: longitude.current, zoomLevel: zoom })
      if (location) {
        setSaveStatus("Saved!")
        setTimeout(() => {
          setSaveStatus(null)
          if (location) navigate(`/app/locations/${location.id}`, { state: { currentLocation: location } })
        }, 1000)
      } else {
        setSaveStatus("Error")
        setTimeout(() => {
          setSaveStatus(null)
        }, 1500)
      }
    }
  }

  const validateForm = () => {
    if (addLocationFormState.city && addLocationFormState.state && addLocationFormState.zipcode) {
      const zipcodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
      const zipcodeValid = zipcodeRegex.test(addLocationFormState.zipcode)
      if (zipcodeValid) {
        return true
      } else {
        alert("Invalid zipcode")
        return false
      }
    } else {
      alert("Please fill out all fields")
      return false
    }
  }

  return (
    <div className="w-full min-h-full flex justify-center">
      <section className="p-5 w-full h-full md:p-5 md:shadow-xl md:rounded-xl bg-white">
        <Header heading="Add Location" paragraph="Prior to adding a new site, make sure you have created the area it will attach to."/>
        <form className="mt-8 space-y-5" onSubmit={handleSubmit}>
          <div className="flex flex-col sm:flex-row">
            <div className="sm:w-1/2 lg:w-1/4 pr-2">
              <CustomSelect list={organizationList ? organizationList : []} handleSelection={handleOrganizationSelect} placeholder="Select Area" />
              {fields.map((field) => (
                <Input
                  className="bg-gray-50 border-gray-300 disabled:bg-gray-200"
                  key={field.id}
                  handleChange={handleChange}
                  value={addLocationFormState[field.id]}
                  labelText={field.labelText}
                  labelFor={field.labelFor}
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  isRequired={field.isRequired}
                  placeholder={field.placeholder}
                />
              ))}
              <button className="bg-secondary rounded-md text-white h-8 w-full" type="button" onClick={handleSearch}>
                Search
              </button>
            </div>
            <section className="flex-grow relative">
      
                <GiCrosshair className=" text-secondary text-5xl absolute z-10 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2" />
                <Map name={name} coordinates={location} handleLocationAdjustment={handleLocation} zoom={zoom} setZoom={setZoom} />
      
            </section>
          </div>
          <SaveButtonWithStatus saveStatus={saveStatus} handleSave={handleSubmit} isForm={true} className="w-full" />
        </form>
      </section>
    </div>
  )
}
