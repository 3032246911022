import { Link } from "react-router-dom"
import Header from "../../components/forms/Header"
import Login from "../../components/login/Login"

export default function LoginPage() {
  return (
    <section className="flex pt-6 md:pt-none md:items-center md:pb-44 justify-center md:w-full md:bg-gradient-to-r from-primary via-secondary to-secondary-light">
      <div className="md:w-2/4 lg:max-w-xl md:p-5 md:shadow-xl md:rounded-xl bg-white">
        <Header heading="Login to Storage Walk" paragraph={""} linkName={""} linkUrl={""} />
        <Login />
        <div className="flex justify-between pt-2">
          <Link to={"/terms-of-service"} className=" text-slate-400">Terms of Service</Link>
          <Link to="/" className="text-black hover:text-primary">Return to Home</Link>
          <Link to={"/privacy-policy"} className=" text-slate-400">Privacy Policy</Link>          
        </div>
      </div>
    </section>
  )
}
