import { useState, useEffect, useMemo } from "react"
import Select, { MultiValue, SingleValue } from "react-select"

interface CustomSelectProps {
  list: any[]
  className?: string
  isMulti?: boolean
  placeholder?: string
  handleSelection?: Function
  required?: boolean
  styles?: any
  defaultValues?: any[]
  sortBy?: "asc" | "desc" | null
}

export default function CustomSelect({ list, className, isMulti = false, placeholder = "Select...", handleSelection, required = false, styles, defaultValues, sortBy = null }: CustomSelectProps) {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<unknown> | SingleValue<unknown>>(isMulti ? [] : null)

  let newList = list
  if (sortBy) {
    if (sortBy === "asc") newList = list ? list.sort((a: { [key: string]: any }, b: { [key: string]: any }) => a.name.localeCompare(b.name)) : []
    if (sortBy === "desc") newList = list ? list.sort((a: { [key: string]: any }, b: { [key: string]: any }) => b.name.localeCompare(a.name)) : []
  }

  const labeledList = useMemo(() => {
    return newList?.map((item: { [key: string]: any }, index: number) => {
      return { value: item.id || item.value || index, label: item.name || item.label, ...item }
    })
  }, [newList]);

  useEffect(() => {
    if (defaultValues && defaultValues.length > 0) {
      const defaultSelectedValues = labeledList.filter((item: { [key: string]: any }) =>
        defaultValues.find((value: any) => value.value === item.value)
      )
      setSelectedOptions(isMulti ? defaultSelectedValues : defaultSelectedValues[0])
      if (handleSelection && defaultSelectedValues.length > 0) {
        handleSelection(isMulti ? defaultSelectedValues : defaultSelectedValues[0])
      }
    }
  }, [defaultValues, labeledList, isMulti])

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: styles?.background || provided.backgroundColor,
      borderColor: state.isFocused ? "#c9a7f0" : provided.borderColor,
      borderWidth: "1px",
      boxShadow: state.isFocused ? "0 0 0 .2px #c9a7f0" : null,
      "&:hover": {
        borderColor: state.isFocused ? "#c9a7f0" : provided.borderColor,
        borderWidth: "1px",
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: "black",
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#006cb0" || provided.backgroundColor,
      color: "white",
    }),
    multiValueRemove: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#8e44ad",
      color: "white",
    }),
  }

  const setHandle = (e: SingleValue<unknown> | MultiValue<unknown>) => {
    setSelectedOptions(e)
    if (handleSelection) handleSelection(e)
  }

  return (
    <div className={`w-full ` + className}>
      <Select
        options={labeledList}
        onChange={setHandle}
        placeholder={placeholder}
        isMulti={isMulti}
        styles={customStyles}
        required={required}
        value={selectedOptions}
      />
    </div>
  )
}
