export const SUPER_USER = 50
export const ADMIN = 45
export const OWNER = 40
export const COMPANY_MANAGER = 30
export const AREA_MANAGER = 20
export const MANAGER = 10
export const ASSISTANT_MANAGER = 5

export interface Roles {
  [key: number]: string
}

// map roles to their names
export const roles: Roles = {
  [SUPER_USER]: "Super User",
  [ADMIN]: "Admin",
  [OWNER]: "Owner",
  [COMPANY_MANAGER]: "Company Manager",
  [AREA_MANAGER]: "Area Manager",
  [MANAGER]: "Manager",
  [ASSISTANT_MANAGER]: "Assistant Manager"
}