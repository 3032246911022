import axios from "axios";
import { ManagementCompany } from "../Interfaces";
import { showErroPage } from "../utils/helper";

class ManagementCompanyService {

  private baseUrl = process.env.REACT_APP_API_URL + "/managementCompany";

  public create = async (managementCompanyInfo: any, imageFile: File | null = null) => {
    try {
      const response = await axios.post(
        `${this.baseUrl}`,
        managementCompanyInfo
      );
      if (imageFile) await this.uploadLogo(imageFile, response.data.id, response.data.name)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public uploadLogo = async (imageFile: File, managementCompanyId: number, managementCompanyName: string) => {
    if (!imageFile) return;
    try {
      const { data: signedUrl } = await axios.get(
        `${this.baseUrl}/update/logo/${managementCompanyId}/${managementCompanyName}`,
      )
      if (imageFile.type !== "image/jpeg" && imageFile.type !== "image/png" && imageFile.type !== "image/jpg") throw new Error("Invalid file type")
      const response = await axios.put(
        signedUrl,
        imageFile,
        {
          headers: {
            "Content-Type": imageFile.type,
            "Authorization": undefined,
          }
        }
      );
      return response.data;
    } catch (error) {
      return error as Error;
    }
  };

  public getViewableLogo = async (managementCompanyId: number, managementCompanyName: string) => {
    try {
      const { data: signedUrl } = await axios.get(
        `${this.baseUrl}/logo/${managementCompanyId}/${managementCompanyName}`,
      )
      return signedUrl;
    } catch (error) {
      return error as Error;
    }
  }

  public getOne = async (id: number) => {
    if(id === 0) return {name: "N/A", id: 0}
    if (!id) throw new Error("No id provided")
    try {
      const response = await axios.get(
        `${this.baseUrl}/${id}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public getByLocation = async (locationId: number): Promise<ManagementCompany | undefined> => {
    if (!locationId) throw new Error("No locationId provided")
    try {
      const response = await axios.get(
        `${this.baseUrl}/by-location/${locationId}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        const statusText = error.response?.statusText
        throw new Error(statusText)
      } else {
        console.error(error)
        showErroPage()
        return undefined
      }
    }

  }
  public getByUser = async (userId: number): Promise<ManagementCompany | undefined> => {
    if (!userId) throw new Error("No userId provided")
    try {
      const response = await axios.get(
        `${this.baseUrl}/by-user/${userId}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        const statusText = error.response?.statusText
        throw new Error(statusText)
      } else {
        console.error(error)
        showErroPage()
        return undefined
      }
    }

  }

  public getAll = async (userId: number): Promise<Partial<ManagementCompany[]>> => {
    //TODO: this route will will check if user has permissions on the backend
    try {
      const response = await axios.get(
        `${this.baseUrl}/all/${userId}`
      );
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        const statusText = error.response?.statusText
        throw new Error(statusText)
      } else {
        console.error(error)
        showErroPage()
        return []
      }
    }
  }

  public update = async (managementCompanyInfo: any, imageFile: File | null = null) => {
    if (!managementCompanyInfo) throw new Error("No managementCompany info provided")
    try {
      const response = await axios.put(
        `${this.baseUrl}`,
        managementCompanyInfo
      );
      if (imageFile) await this.uploadLogo(imageFile, response.data.id, response.data.name)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public delete = async (id: number) => {
    if (!id) throw new Error("No id provided")
    try {
      const response = await axios.delete(
        `${this.baseUrl}/${id}`
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }
}

export const managementCompanyService = new ManagementCompanyService()

