import { FormEventHandler, MouseEventHandler } from "react"

interface SaveButtonProps {
  saveStatus: string | null
  handleSave: FormEventHandler<HTMLButtonElement> | MouseEventHandler<HTMLButtonElement>
  text?: string
  className?: string
  backgroundColor?: string
  isForm?: boolean
}
export default function SaveButtonWithStatus({ saveStatus, handleSave, text, className = "", backgroundColor = "bg-primary", isForm = false }: SaveButtonProps) {
  return isForm ? (
    <button type={'submit'} className={`${saveStatus ? (saveStatus !== "Error" ? "bg-green-500" : "bg-red-500") : backgroundColor} text-white p-2 rounded-lg hover:shadow-right-lg transform active:translate-y-1 active:scale-[.99]  active:shadow-none font-medium ` + className} onSubmit={handleSave}>
      {saveStatus ? saveStatus : text || "Submit"}
    </button>
  ) : (
    <button className={`${saveStatus ? (saveStatus !== "Error" ? "bg-green-500" : "bg-red-500") : backgroundColor} text-white p-2 rounded-lg hover:shadow-right-lg transform active:translate-y-1 active:scale-[.99]  active:shadow-none font-medium ` + className} onClick={(e) => {
      handleSave(e)
      }}>
      {saveStatus ? saveStatus : text || "Save"}
    </button>
  )
}
