import { NavLink } from "react-router-dom"
import { useContext } from "react"
import UiContext from "../../context/uiProvider"
import { SMALL_TABLET } from "../../utils/screenSize"

export default function SidebarLink({ children, to }: any) {
  const { sidebarOpen: open, deviceView } = useContext(UiContext)

  return (
    <NavLink to={to} className={({ isActive }) => (
      isActive ? `bg-primary w-full flex items-center p-3 ${open && deviceView >= SMALL_TABLET 
        ? "pl-7" : "px-0 justify-center"}` 
        : `flex items-center p-3 ${open && deviceView >= SMALL_TABLET ? "pl-7" : "px-0 justify-center"}`)}>
      {children}
    </NavLink>
  )
}
