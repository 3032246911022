import { useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import UiContext from "../../context/uiProvider"
import Logout from "../logout"
import useUser from "../../hooks/useUser"
import { LARGE_TABLET, TABLET } from "../../utils/screenSize"
import DataContext from "../../context/dataProvider"

export default function Navbar() {
  const path = useLocation().pathname.split("/")
  const { firstName, lastName, id } = useUser()
  const { sidebarOpen, deviceView } = useContext(UiContext)
  const { currentUser } = useContext(DataContext)
  if (deviceView >= LARGE_TABLET) {
    return (
      <nav className={`${sidebarOpen ? "pl-72" : "pl-28"} z-10 duration-300 ease-in w-full bg-slate-100 shadow-md h-12 flex items-center justify-between text-xl font-bold fixed px-8 text-black top-0`}>
        <Link to={`/app/users/${id}`} state={{ user: currentUser }} >
          <div>{currentUser.firstName + " "  + currentUser.lastName}</div>
        </Link>
        <Logout />
      </nav>
    )
  } else if (deviceView >= TABLET) {
    return (
      <nav className={`${sidebarOpen ? "pl-60" : "pl-28"} z-10 duration-300 ease-in w-full bg-slate-100 shadow-md h-12 flex items-center justify-between text-xl font-bold fixed px-8 text-black top-0`}>
        <Link to={`/app/users/${id}`} state={{ user: currentUser }} >
          <div>{currentUser.firstName + " "  + currentUser.lastName}</div>
        </Link>
        <Logout />
      </nav>
    )
  } else {
    return (
      <nav className={`pl-20 w-full z-10 bg-slate-100 shadow-md h-12 flex items-center justify-between text-xl font-bold fixed px-8 text-black top-0`}>
        <Link to={`/app/users/${id}`} state={{ user: currentUser }} >
          <div>{currentUser.firstName + " "  + currentUser.lastName}</div>
        </Link>
        <Logout />
      </nav>
    )
  }
}
