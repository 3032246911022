import React from "react"
import { PaginationProps } from "../../../Interfaces"

const Pagination: React.FC<PaginationProps> = ({ total, itemsPerPage, currentPage, onPageChange }) => {
  // Assuming a fixed number of pages for simplicity, but this could be dynamic
  const totalPages = Math.ceil(total / itemsPerPage)
  // Determine the range of pages to display
  let startPage: number, endPage: number
  if (totalPages <= 5) {
    // Less than 5 total pages so show all
    startPage = 1
    endPage = totalPages
  } else {
    // More than 5 total pages so calculate start and end pages
    if (currentPage <= 3) {
      startPage = 1
      endPage = 5
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4
      endPage = totalPages
    } else {
      startPage = currentPage - 2
      endPage = currentPage + 2
    }
  }

  // Generate the page number buttons
  let pages = Array.from({ length: endPage + 1 - startPage }, (_, i) => startPage + i)
  // always include the first page and the last page
  pages.unshift(1)
  pages.push(totalPages)
  // remove duplicates
  pages = [...new Set(pages)]
  if (totalPages <= 1) return null

  return (
    <>
      {pages.map((pageNumber, i) => {
        return (
          <React.Fragment key={i}>
            {pageNumber === totalPages && pages[i - 1] !== totalPages - 1 && <span className="leading-4">...</span>}
            <button className={`px-1 mx-0.5 w-6 rounded-md text-white ${currentPage === pageNumber ? "bg-primary" : "bg-secondary hover:bg-secondary-dark"}`} onClick={() => onPageChange(pageNumber)} disabled={currentPage === pageNumber}>
              {pageNumber}
            </button>
            {pageNumber === 1 && pages[i + 1] !== 2 && <span className="leading-4">...</span>}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Pagination
