import SaveButtonWithStatus from "../general/SaveButtonWithStatus"
import Input from "../forms/Input"

interface ConfirmationModalProps {
  isOpen: boolean
  title: string
  message: string
  onConfirm: (e: { preventDefault: () => void }) => Promise<void> | void
  onCancel: (e: { preventDefault: () => void }) => void
  handleWalkName?: (e: { target: { value: string } }) => void
  handleWalkDescription?: (e: { target: { value: string } }) => void
  walkName?: string
  walkDescription?: string
  status?: string | null
}

export default function NewWalkModal({ isOpen, title, message, onConfirm, onCancel, handleWalkName, handleWalkDescription, walkName, walkDescription, status }: ConfirmationModalProps) {
  if (!isOpen) return null

  return (
    <div className="fixed z-50 top-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <form className="bg-white rounded shadow-lg p-6 w-96" onSubmit={(e) => e.preventDefault()}>
        <h2 className="text-xl font-bold mb-4">{title + " - " + (walkName ? walkName : "New Walk")}</h2>
        <p className="mb-4">{message}</p>

        <Input className="w-full" value={walkName || ""} handleChange={handleWalkName} placeholder={`Walk name: ie. Night Walk 1`} labelText={""} labelFor={""} id={""} name={"walkName"} type={"text"} maxLength={30} isRequired={true} />
        <Input className="w-full" value={walkDescription || ""} handleChange={handleWalkDescription} placeholder="Walk Description" labelText={""} labelFor={""} id={""} name={"walkDescription"} type={"text"} maxLength={200} />
        <div className="flex justify-end space-x-2">
          <button onClick={onCancel} className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:shadow-right-lg transform active:translate-y-1 active:scale-[.99]  active:shadow-none font-medium">
            Cancel
          </button>
          <SaveButtonWithStatus text="Confirm" saveStatus={status || null} handleSave={onConfirm} />
        </div>
      </form>
    </div>
  )
}
