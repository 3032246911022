import { FallbackProps } from 'react-error-boundary';


export default function ErrorFallback(props: FallbackProps) {
  const { error, resetErrorBoundary } = props
  
  if ( error.message === "Conflict"){
    return (
      <>
      <div className='flex w-full h-full bg-transparent justify-center items-center'>  
        <div className="flex flex-col items-center justify-center p-8 shadow-lg rounded-lg bg-white"><h2>An error occured. This may be related to duplicate accounts. please try again.</h2>
        <button  className='p-2 mt-4 bg-primary rounded-lg text-white font-semibold' onClick={ resetErrorBoundary }>Try Again</button>
        </div>
      </div>
      </>
    )
    
  } else if ( error.message === "Unauthorized"){
    return (
      <div className='flex w-full h-full bg-transparent justify-center items-center'>  
        <div className="flex flex-col items-center justify-center p-8 shadow-lg rounded-lg bg-white"><h2>Unauthorized!</h2>
        <button  className='p-2 mt-4 bg-primary rounded-lg text-white font-semibold' onClick={ resetErrorBoundary }>Reset</button>
        </div>
      </div>
    )
  } else if ( error.message === "Unknown Error"){
  return (
    <div className='flex w-full h-full bg-transparent justify-center items-center'>  
    <div className="flex flex-col items-center justify-center p-8 shadow-lg rounded-lg bg-white"><h2>Oops! An unknown error occured!</h2>
    <button  className='p-2 mt-4 bg-primary rounded-lg text-white font-semibold' onClick={ resetErrorBoundary }>Reset</button>
    </div>
  </div>
  ) 
  } else if ( error.message === "Internal Server Error"){
    return (
      <div className='flex w-full h-full bg-transparent justify-center items-center'>  
      <div className="flex flex-col items-center justify-center p-8 shadow-lg rounded-lg bg-white"><h2>Oops! An unknown error occured! Please try again later.</h2>
      <button  className='p-2 mt-4 bg-primary rounded-lg text-white font-semibold' onClick={ resetErrorBoundary }>Reset</button>
      </div>
    </div>
    ) 
    } 
  else if ( error.message ){
    return (
      <div className='flex w-full h-full bg-transparent justify-center items-center'>  
      <div className="flex flex-col items-center justify-center p-8 shadow-lg rounded-lg bg-white"><h2>{error.message}</h2>
      <button  className='p-2 mt-4 bg-primary rounded-lg text-white font-semibold' onClick={ resetErrorBoundary }>Reset</button>
      </div>
    </div>
    )
  } else {
    return (
      <div className='flex w-full h-full bg-transparent justify-center items-center'>  
      <div className="flex flex-col items-center justify-center p-8 shadow-lg rounded-lg bg-white"><h2>Oops! An unknown error occured! Check your internet connection.</h2>
      <button  className='p-2 mt-4 bg-primary rounded-lg text-white font-semibold' onClick={ resetErrorBoundary }>Reset</button>
      </div>
    </div>
    )
  }
 
}