import axios from "axios"
import { Credentials, User } from "../Interfaces"


class AuthService {
  private token = localStorage.getItem("token")
  private baseUrl = process.env.REACT_APP_API_URL + "/auth";

  async getAuthFromCredentials(credentials: Credentials) {
    try {
      const response = await axios.post(
        `${this.baseUrl}/login`,
        credentials
      )
      await this.setToken(response.data.accessToken, response.data.user)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error);
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
    }
  }

  // get athuthenticated user
  async getAthenticatedUser() {
    if (!this.token) throw new Error("Couldn't find token")
    axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`
    const response = await axios.get("http://localhost:3000/api/authenticate")
    return response.data
  }

  async forgotPassword(email: string) {
    try {
      const response = await axios.post(
        `${this.baseUrl}/forgot-password`,
        { email }
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error);
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
    }
  }

  async resetPassword(token: string, password: string) {
    try {
      const response = await axios.post(
        `${this.baseUrl}/reset-password`,
        { token, password }
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error);
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
    }
  }

  async setToken(token: string, user: User) {
    localStorage.setItem("token", token)
    localStorage.setItem("user", JSON.stringify(user))
    // save to context provider
  }

  logout() {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
  }
}

export const authService = new AuthService()
