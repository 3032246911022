import React from "react"
import ReactDOM from "react-dom/client"
import "./input.css"
import App from "./App"
import { AuthProvider } from "./context/authProvider"
import { UiProvider } from "./context/uiProvider"
import { DataProvider } from "./context/dataProvider"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ErrorBoundary } from "react-error-boundary"
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ErrorFallback from "./pages/errors/ErrorFallback"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: true,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthProvider>
          <UiProvider>
            <DataProvider>
              <App />
            </DataProvider>
          </UiProvider>
        </AuthProvider>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
)
