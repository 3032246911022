import { Link, useParams } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import PopupModal from "../../../components/modals/PopupModal"
import LocationUsersTable from "../../../components/tables/LocationUsersTable"
import LocationWalkReportsTable from "../../../components/tables/LocationWalkReportsTable"
import DataContext from "../../../context/dataProvider"
import { useQuery } from "@tanstack/react-query"
import UiContext from "../../../context/uiProvider"
import { TABLET } from "../../../utils/screenSize"
import { locationService } from "../../../services/locationService"
import { AREA_MANAGER } from "../../../utils/roles"
import { incidentService } from "../../../services/IncidentService"
import CustomSelect from "../../../components/forms/CustomSelect"
import IncidentsView from "../../../components/general/IncidentsView"
import { Incident } from "../../../Interfaces"
export default function SingleLocation() {
  const { deviceView } = useContext(UiContext)
  const { setCurrentLocation, currentUser } = useContext(DataContext)
  const { id } = useParams()
  const locationId = Number(id)
  const [showWalkReports, setShowWalkReports] = useState<boolean>(true)
  const [showPopupModal, setShowPopupModal] = useState<boolean>(false)
  const [selectedView, setSelectedView] = useState<string>("Walk Reports")
  const [incidents, setIncidents] = useState<Incident[]>([])
  const [showUnresolvedOnly, setShowUnresolvedOnly] = useState<boolean>(true)

  const { data: currentLocation } = useQuery(["location", locationId], () => locationService.getOne(Number(locationId)), {
    enabled: locationId !== undefined,
  })

  const { data: organizationName } = useQuery<string>(["organization-name", locationId], () => locationService.getOrganizationName(locationId), {
    enabled: locationId !== undefined,
  })

  const { data: managementCompanyName } = useQuery<string>(["management-company-name", locationId], () => locationService.getManagementCompanyName(locationId), {
    enabled: locationId !== undefined,
  })

  useEffect(() => {
    if (!currentLocation) {
      setCurrentLocation(currentLocation)
    }
  }, [currentLocation, setCurrentLocation])

  useEffect(() => {
    if (locationId) {
      fetchIncidents()
    }
  }, [locationId, showUnresolvedOnly])

  const fetchIncidents = async () => {
    try {
      const fetchedIncidents = await incidentService.getAllByLocation({
        locationId,
        unresolvedOnly: showUnresolvedOnly
      })
      setIncidents(fetchedIncidents)
    } catch (error) {
      console.error("Error fetching incidents:", error)
    }
  }

  const handleTableView = () => {
    setShowWalkReports(!showWalkReports)
  }

  const handleViewChange = (selected: any) => {
    if (selected && selected.value) {
      console.log("selected", selected)
      setSelectedView(selected.value)
    }
  }

  if (!locationId) return null

  return (
    <>
      <div className="w-full min-h-full flex justify-center overflow-auto h-full">
        <div className="w-full flex flex-col ">
          <section className="bg-secondary-extra-light p-2 md:rounded-lg mb-2 shadow-lg ">
            <div className="bg-primary-dark flex flex-col">
              <div className=" text-white rounded-lg p-4 flex justify-between">
                <div className={`${deviceView > TABLET && "flex"}`}>
                  <div className="mr-5">
                    <h3 className="">{currentLocation.name}</h3>
                    <h4 className="">{currentLocation.address}</h4>
                    <h4 className="">{`${currentLocation.city}, ${currentLocation.state} ${currentLocation.zipcode}`}</h4>
                  </div>
                  <div>
                    {managementCompanyName && (
                      <h4>
                        Management Company:<span className="text-base">{" " + managementCompanyName}</span>
                      </h4>
                    )}
                    <h4 className="">
                      Area:<span className="text-base">{" " + organizationName}</span>
                    </h4>
                  </div>
                </div>
                <div>
                  <div className="flex justify-end p-2">
                    <CustomSelect
                      list={[
                        { value: "Walk Reports", label: "Walk Reports" },
                        { value: "Employees", label: "Employees" },
                        { value: "Incidents", label: "Incidents" }
                      ]}
                      placeholder="Select View"
                      className="rounded-lg w-40"
                      styles={{ background: "#daf1ff" }}
                      defaultValues={[{ value: selectedView, label: selectedView }]}
                      handleSelection={handleViewChange}
                    />
                    <Link to={`./create-walks`} className="rounded-lg p-2 ml-3 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light" state={{ currentLocation }}>
                      Setup Walks
                    </Link>
                    {
                      currentUser?.roleId && currentUser.roleId >= AREA_MANAGER &&
                      <Link to={`../locations/update/${id}`} className="rounded-lg p-2 ml-3 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light" state={{ currentLocation, organizationName }}>
                        Update Location
                      </Link>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
          {selectedView === "Walk Reports" && <LocationWalkReportsTable locationId={locationId} />}
          {selectedView === "Employees" && <LocationUsersTable locationId={locationId} />}
          {selectedView === "Incidents" && (
            <IncidentsView
              locationId={locationId}
              incidents={incidents}
              showUnresolvedOnly={showUnresolvedOnly}
              setShowUnresolvedOnly={setShowUnresolvedOnly}
              coordinates={{ lat: currentLocation.latitude, lng: currentLocation.longitude }}
              zoom={currentLocation.zoomLevel || 18}
            />
          )}
        </div>
        {showPopupModal && (
          <PopupModal isOpen={showPopupModal} onCancel={() => setShowPopupModal(false)}>
            <div className="w-full flex flex-wrap sm:rounded-lg m-4 p-4">
              <LocationUsersTable locationId={currentLocation.id || 0} />
            </div>
          </PopupModal>
        )}
      </div>
    </>
  )
}
