import { useQuery } from "@tanstack/react-query"
import { Organization} from "../../Interfaces"
import { CgSpinner } from "react-icons/cg"
import genericOrganizationImage from "../../assets/generic-org-icon.png"
import { organizationService } from "../../services/organizationService"

interface ImageProps {
  company: Organization
}

const OrganizationLogo: React.FC<ImageProps> = ({ company }) => {
  const { data, isLoading, isFetching, isError } = useQuery(["organizationLogo", company.id], () => organizationService.getViewableLogo(company.id, company.name))

  function handleFallback (e: React.SyntheticEvent<HTMLImageElement, Event>) {
    e.currentTarget.onerror = null
    e.currentTarget.src = genericOrganizationImage
  }

  if (isLoading || isFetching || data instanceof Promise) {
    return (
      <div className="w-44 h-44 lg:w-44 lg:h-44 rounded-full mx-auto border-4 border-secondary-extra-light flex justify-center items-center">
        <CgSpinner className="animate-spin h-12 w-12 text-primary-light" />
      </div>
    )
  }
  if (isError) {
    return <img src={genericOrganizationImage} alt="User Profile Generic" className="w-44 h-44 lg:w-44 lg:h-44 rounded-full mx-auto border-4 border-secondary-extra-light" />
  }

  return <img src={data} alt="User Profile" onError={handleFallback} className="w-44 h-44 lg:w-44 lg:h-44 rounded-full mx-auto border-4 border-secondary-extra-light" />
}

export default OrganizationLogo
