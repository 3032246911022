import { useState } from "react"
import Header from "../../components/forms/Header"
import { addUserFields } from "../../constants/formFields"
import CustomSelect from "../../components/forms/CustomSelect"
import { ADMIN, AREA_MANAGER, ASSISTANT_MANAGER, COMPANY_MANAGER, MANAGER, OWNER, roles } from "../../utils/roles"
import AddUserForm from "../../components/forms/AddUserForm"
import useUser from "../../hooks/useUser"

export default function AddUser() {
  const { roleId } = useUser()
  if( !roleId ) alert("You are not authorized to view this page")
  const [newUserRole, setNewUserRole] = useState<number>(0)

  // maps role numbers to role names
  const rolesList = [
    { value: ADMIN, name: roles[ADMIN]},
    { value: OWNER, name: roles[OWNER]},
    { value: COMPANY_MANAGER, name: roles[COMPANY_MANAGER] },
    { value: AREA_MANAGER, name: roles[AREA_MANAGER] },
    { value: MANAGER, name: roles[MANAGER] },
    { value: ASSISTANT_MANAGER, name: roles[ASSISTANT_MANAGER]}
  ]

  const handleRoleSelection = (item: any) => {
    setNewUserRole(item.value)
  }

  return (
      <div className="w-full min-h-full flex justify-center">
        <section className="p-5 w-full h-full lg:max-w-4xl md:p-5 md:shadow-xl md:rounded-xl bg-white max-h-screen overflow-auto">
          <Header heading={newUserRole ? `Add ${roles[newUserRole]}` : `Add Employee`} />
          <div className="lg:flex lg:justify-evenly items-center">
            <section className="lg:w-full">
              <div>
                <CustomSelect list={rolesList.filter(role => role.value < roleId!!)} placeholder="Select Employee Role" handleSelection={handleRoleSelection} />
                <AddUserForm fieldType={addUserFields} newEmployeeRole={newUserRole} />
              </div>
            </section>
          </div>
        </section>
      </div>
  )
}
