import Header from "../../components/forms/Header"
import ResetPassword from "../../components/login/ResetPassword"

export default function ResetPasswordPage() {

  return (
    <section className="flex pt-6 md:pt-none md:items-center md:pb-44 justify-center md:w-full md:bg-gradient-to-r from-primary via-secondary to-secondary-light">
    <div className="md:w-2/4 lg:max-w-xl md:p-5 md:shadow-xl md:rounded-xl bg-white">
        <Header heading="Reset your password" paragraph="Remember your password?" linkName="Login" linkUrl="/" />
        <p className="text-center text-sm text-gray-600 mt-5">Please enter your email address below and we'll send you instructions on how to reset your password.</p>
        <ResetPassword />
      </div>
    </section>
  )
}
