import { Location, NavigateFunction } from "react-router-dom"

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const phoneNumberAutoFormat = (phoneNumber: string): string => {
  const number = phoneNumber.trim().replace(/[^0-9]/g, "")
  if (number[0] === "1") return number.slice(1)
  if (number.length < 4) return number
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2")
  return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3")
}

export const cleanPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, "")
}

export const navigation: { navigate: null | NavigateFunction; location: null | Location } = {
  navigate: null,
  location: null,
}

export const showErroPage = () => {
  if (navigation.navigate) navigation.navigate("/app/error")
}

export const doPasswordsMatch = (password: string, confirmPassword: string) => {
  if (password !== confirmPassword) {
    alert("Passwords do not match")
    return false
  }
  return true
}

export const getCoordinates = async (address: string) => {
  const geocoder = new google.maps.Geocoder()
  let latLong: google.maps.LatLngLiteral | google.maps.LatLng = { lat: 0, lng: 0 }
  let lat: number = 0
  let lng: number = 0
  await geocoder.geocode({ address }, (results, status) => {
    if (status === "OK") {
      if (results) {
        latLong = results[0].geometry.location
        lat = latLong.lat()
        lng = latLong.lng()
      } else {
        return null
      }
    } else {
      alert("Geocode was not successful for the following reason: " + status)
    }
    return null
  })
  return { lat, lng }
}

export function isEmpty(obj: {} | [] | null | undefined) {
  if (!obj) return true
  return Object.keys(obj).length === 0
}

export function sortByAttribute(arr: any[], attribute: string, ascending: boolean = true) {
  if (!arr) return []
  if (ascending) {
    const sortedArr = arr.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
      if (a[attribute] < b[attribute]) return -1
      if (a[attribute] > b[attribute]) return 1
      return 0
    })
    return sortedArr
  } else {
    const sortedArr = arr.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
      if (a[attribute] > b[attribute]) return -1
      if (a[attribute] < b[attribute]) return 1
      return 0
    })
    return sortedArr
  }
}

export function getTimeDifference(date1: Date, date2: Date) {
  // Calculate the difference in milliseconds
  const diff = Math.abs(date1.getTime() - date2.getTime());

  // Convert milliseconds to hours, minutes, and seconds
  let hours: number | string = Math.floor(diff / 3600000)
  let minutes: number | string = Math.floor((diff % 3600000) / 60000);
  let seconds: number | string = Math.floor((diff % 60000) / 1000);

  if (minutes < 10) minutes = `0${minutes}`
  if (seconds < 10) seconds = `0${seconds}`
  // Return the result as an object

  return { hours, minutes, seconds };
}


export function validatePasswords(password: string, confirmPassword: string) {
  if (password !== confirmPassword) {
    return false
  } else if (password.length < 6) {
    return false
  }
  return true
}