import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="w-full mx-auto py-14 px-6" style={{backgroundColor: "#0C233C"}}>
        <div className="flex flex-row justify-center items-center text-white font-light">
            <Link to={"/terms-of-service"} className="text-white underline px-2 hover:text-gray-500">
                Terms of Service
            </Link>
            <Link to={"/privacy-policy"} className= "text-white underline px-2 hover:text-gray-500">
                Privacy Policy
            </Link>
        </div>
        <div className="flex justify-center pt-4 items-end font-light text-white xs:text-xs sm:text-sm">
            © 2024 AuraTechnology, Inc. All Rights Reserved
        </div>
    </div>
  )
}

export default Footer