import axios from "axios"
import { Incident, AddIncidentNote, ResolveIncident } from "../Interfaces";

class IncidentService {
  private baseUrl = process.env.REACT_APP_API_URL + "/incident";
  // CREATE ==============================================
  public addNote = async (incidentInfo: AddIncidentNote,) => {
    try {
      if (!incidentInfo || !incidentInfo.note || !incidentInfo.userId) throw new Error("Missing incident info")
      const response = await axios.post(
        `${this.baseUrl}/note`,
        incidentInfo,
      );
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
      } else {
        throw Error(error)
      }
    }
  }

  // READ ==============================================
  public getOne = async (incidentId: number) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/${incidentId}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public getAllByLocation = async ({ locationId, unresolvedOnly }: { locationId: Number, unresolvedOnly: boolean }): Promise<Incident[]> => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/location?locationId=${locationId}&unresolvedOnly=${unresolvedOnly}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
        throw new Error("axios error")
      } else {
        console.error(error);
      }
    }
    return []
  }

  // UPDATE ==============================================
  public resolve = async (incidentInfo: ResolveIncident) => {
    try {
      if (!incidentInfo || !incidentInfo.incidentId) throw new Error("Missing incident info")
      if (incidentInfo.resolved) {
        const response = await axios.post(
          `${this.baseUrl}/${incidentInfo.incidentId}/resolve`,
          { incidentInfo }
        );
        return response.data
      } else {
        const response = await axios.post(
          `${this.baseUrl}/${incidentInfo.incidentId}/unresolve`,
          { incidentInfo }
        );
        return response.data
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
      } else {
        throw Error(error)
      }
    }
  }
}

export const incidentService = new IncidentService()