import React, { useState, FunctionComponent, useEffect } from 'react';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
  className?: string;
}

const Tooltip: FunctionComponent<TooltipProps> = ({ children, text, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  let enterTimeout: NodeJS.Timeout;

  useEffect(() => {
    if(isVisible) {
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 3000); // Delay of 3 seconds before hiding the tooltip
      return () => clearTimeout(timeout);
    }
  }, [isVisible]);

  const showTooltip = () => {
    enterTimeout = setTimeout(() => {
      setIsVisible(true);
    }, 700); // Delay of .7 seconds before showing the tooltip
  };

  const hideTooltip = () => {
    clearTimeout(enterTimeout); // Clear the timeout if mouse leaves before the tooltip appears
    setIsVisible(false);
  };

  const tooltipStyle: React.CSSProperties = {
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 0.3s ease-in ', 
    scale: 1.1,
    backdropFilter: 'blur(10px)',
    pointerEvents: isVisible ? 'auto' : 'none',
  };

  // Cleanup the timeout when the component is unmounted
  useEffect(() => {
    return () => {
      clearTimeout(enterTimeout);
    };
  }, []);

  return (
    <div className={`relative z-50`}>
      <div
        className={className || ""}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>
      <div
        style={tooltipStyle}
        className="absolute bottom-full mb-2 px-4 py-2 text-sm text-white bg-black bg-opacity-50 rounded-md"
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;


// import React, { useState } from 'react';

// const Tooltip = ({ children, text }) => {
//   const [opacity, setOpacity] = useState(0);

//   return (
//     <div className="relative flex items-center">
//       <div
//         onMouseEnter={() => setOpacity(1)}
//         onMouseLeave={() => setOpacity(0)}
//       >
//         {children}
//       </div>
//       <div
//         style={{ opacity: opacity, transition: 'opacity 0.3s ease-in-out' }}
//         className="absolute bottom-full mb-2 px-4 py-2 text-sm text-white bg-black bg-opacity-50 rounded-md"
//       >
//         {text}
//       </div>
//     </div>
//   );
// };

// export default Tooltip;