import { useQuery } from "@tanstack/react-query"
import { userService } from "../../services/userService"
import { User } from "../../Interfaces"
import { CgSpinner } from "react-icons/cg"
import genericProfileImage from "../../assets/generic-profile-pic.png"

interface ImageProps {
  user: User
}

const ProfileImage: React.FC<ImageProps> = ({ user }) => {
  const { data, isLoading, isFetching, isError } = useQuery(["userImage", user.id], () => userService.getViewableProfileImage(user.id, user.firstName!, user.lastName!))

  function handleFallback (e: React.SyntheticEvent<HTMLImageElement, Event>) {
    e.currentTarget.onerror = null
    e.currentTarget.src = genericProfileImage
  }

  // the data returned might be a promise, so we need to check if it is resolved
  if (data instanceof Promise) {
    return <div>Loading image...</div>
  }

  if (isLoading || isFetching) {
    return (
      <div className="w-44 h-44 lg:w-44 lg:h-44 rounded-full mx-auto border-4 border-secondary-extra-light flex justify-center items-center">
        <CgSpinner className="animate-spin h-12 w-12 text-primary-light" />
      </div>
    )
  }
  if (isError) {
    return <img src={genericProfileImage} alt="User Profile Generic" className="w-44 h-44 lg:w-44 lg:h-44 rounded-full mx-auto border-4 border-secondary-extra-light" />
  }

  return <img src={data} alt="User Profile" onError={handleFallback} className="w-44 h-44 lg:w-44 lg:h-44 rounded-full mx-auto border-4 border-secondary-extra-light" />
}

export default ProfileImage
