import { useState, useRef } from "react"
import HomeNavbar from "../../components/homecomponents/HomeNavbar"
import Hero from "../../components/homecomponents/Hero"
import Reviews from "../../components/homecomponents/Reviews"
import Demo from "../../components/homecomponents/Demo"
import About from "../../components/homecomponents/About"
import PricingPlan from "../../components/homecomponents/PricingPlan"
import Faq from "../../components/homecomponents/Faq"
import Footer from "../../components/homecomponents/Footer"
import WhyStorageWalk from "../../components/homecomponents/WhyStorageWalk"
import Features from "../../components/homecomponents/Features"

const Home = () => {
  const demoRef = useRef<HTMLDivElement | null>();
  const aboutRef = useRef<HTMLDivElement | null>();
  const pricingRef = useRef<HTMLDivElement | null>();
  const faqRef = useRef<HTMLDivElement | null>();
  const whyStorageWalkRef = useRef<HTMLDivElement | null>();

  return (
    <div className="w-full">
        <HomeNavbar whyStorageWalk={whyStorageWalkRef} demo={demoRef} about={aboutRef} pricing={pricingRef} faq={faqRef}/>
        <Hero/>
        {/*<Demo demo={demoRef}/>*/}
        <WhyStorageWalk whyStorageWalk={whyStorageWalkRef}/>
        <About about={aboutRef}/>
        <Features/>
        <PricingPlan pricing={pricingRef}/>
        {/*<Faq faq={faqRef}/>
        <Reviews/>*/}
        <Footer/>
    </div>
  )
}

export default Home