import { Link, useLocation } from "react-router-dom"
import OrganizationUsersTable from "../../../components/tables/OrganizationUsersTable"
import OrganizationLocationsTable from "../../../components/tables/OrganizationLocationsTable"
import OrganizationLogo from "../../../components/images/OrganizationLogo"

export default function SingleOrganization() {
  const location = useLocation()
  const { currentItem: organization } = location.state
  


  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full md:max-w-3xl flex flex-wrap bg-secondary-extra-light p-2 sm:rounded-lg shadow-lg h-min ">
        <div className="bg-primary-dark text-white p-4 rounded-lg justify-self-start w-full md:w-56 lg:w-64 m-2 ">
          <div className="relative w-fit m-auto hover:cursor-pointer">
            <OrganizationLogo company={organization} />
          </div>
          <h2 className="text-xl mt-4 text-center">{`${organization.name}`}</h2>
          <p className="text-center">ID: {organization.id}</p>
        </div>
        <div className="bg-primary-dark text-white p-4 rounded-lg justify-self-start m-2 max-w-full flex-1 whitespace-nowrap relative">
          <Link to={`../organizations/update/${organization.id}`} state={{ organization }}>
            <button className="rounded-lg p-2 text-white text-center bg-primary border-sedondary absolute top-3 right-3  hover:border-2 hover:border-primary-light">Update</button>
          </Link>
          {organization.createdAt && (
            <p>
              <strong>Created: </strong>
              {new Date(organization.createdAt).toLocaleDateString()}
            </p>
          )}
          <p className=" whitespace-normal pr-16">
            <strong>Management Company: </strong>
            {organization.managementCompanyName}
          </p>
          <p>
            <strong>Total Locations: </strong>
            {organization.totalLocations}
          </p>
          <p>
            <strong>Total Employees: </strong>
            {organization.totalEmployees}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
        <OrganizationUsersTable organizationId={organization.id} />
      </div>
      <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
        <OrganizationLocationsTable organizationId={organization.id} />
      </div>
    </div>
  )
}
