import { HeaderProps } from '../../Interfaces'
// import logo from '../../assets/svg/logo-no-background.svg'
import logo from '../../assets/logo-black-no-background.png'

export default function Header({
    heading, paragraph="",
}: HeaderProps){
    return(
        <div className="mb-10">
            <div className="flex justify-center">
                <img 
                    alt="shield"
                    className="p-8 h-40 max-w-md"
                    src={logo}/>
            </div>
            <h2 className="mt-4 text-center text-gray-900">
                {heading}
            </h2>
            <p className="text-center text-sm text-gray-600 mt-5">
              {paragraph}  </p>
        </div>
    )
}