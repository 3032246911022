import React from 'react'
import databaseSolid from "../../logo/database-solid.svg";
import shieldHalvedSolid from "../../logo/shield-halved-solid.svg";
import tableColumnsSolid from "../../logo/table-columns-solid.svg";
import peopleGroupSolid from "../../logo/people-group-solid.svg";


const FeatureData = [
    {
        title: "Interactive Dashboard",
        description: "Log In to see a comprehensive overview of all your facilities. Filter by date, issue type or location for detailed insights.",
        icon: tableColumnsSolid
    },
    {
        title: "Collaborative Tools",
        description: "Share notes and tasks directly from the app. Assign follow-up actions to team members with deadlines and priority levels.",
        icon: peopleGroupSolid
    },
    {
        title: "Historical Data",
        description: "Review past walks and track improvements or recurring issues over time, helping in predictive maintenance and resource allocation.",
        icon: databaseSolid
    },
    {
        title: "Security First",
        description: "With end-to-end encryption, your data is secure from the moment it's captured until it's reviewed.",
        icon: shieldHalvedSolid
    }
]

const Features = () => {
  return (
    <div className="w-full mx-auto py-20 px-6" style={{backgroundColor: "#F0E3E0"}}>
        <div className="flex justify-center flex-col">
            <div className="flex tracking-wide md:text-3xl xs:text-2xl justify-center font-semibold" style={{color: "#0C233C"}}>
                Features that Make a Difference
            </div>
            <div className="flex justify-center">
            <ul className="flex w-full justify-center py-12 flex-wrap">
                {FeatureData.map((item, index) => (
                    <div className="flex items-center flex-col xs:w-3/4 sm:w-1/3 lg:w-1/5 py-12 my-4  mx-4 text-white" style={{backgroundColor: "#FF5C36"}}>
                        <img src={item.icon} alt={item.title} className="w-10 h-8 mb-4" style={{color: "white"}}/>
                        <li className="text-lg font-bold px-2 text-center"> {item.title} </li>
                        <li className='flex py-2 items-start justify-center h-full text-center mt-2 px-4'> {item.description} </li>
                    </div>
                ))}
            </ul>
        </div>
        </div>
        
    </div>
  )
}

export default Features