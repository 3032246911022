import { useNavigate, useParams } from "react-router-dom"
import LocationsTable from "../../../components/tables/LocationsTable"
import UsersTable from "../../../components/tables/UsersTable"
import { FaPlus, FaRegEye } from "react-icons/fa"
import { Location, Organization, User, UserToLocation, UserToOrganization } from "../../../Interfaces"
import { AREA_MANAGER, ASSISTANT_MANAGER, MANAGER, roles, ADMIN, OWNER } from "../../../utils/roles"
import SaveButtonWithStatus from "../../../components/general/SaveButtonWithStatus"
import { isEmpty, phoneNumberAutoFormat, validatePasswords } from "../../../utils/helper"
import { useRef, useState } from "react"
import useDevice from "../../../hooks/useDevice"
import { SMALL_TABLET } from "../../../utils/screenSize"
import { locationService } from "../../../services/locationService"
import { useQuery } from "@tanstack/react-query"
import useUser from "../../../hooks/useUser"
import CustomSelect from "../../../components/forms/CustomSelect"
import { userService } from "../../../services/userService"
import { organizationService } from "../../../services/organizationService"
import { useErrorBoundary } from "react-error-boundary"
import { MultiValue } from "react-select"
import { useQueryClient } from "@tanstack/react-query"
import ConfirmationModal from "../../../components/modals/ConfirmationModal"
import ImageDragDrop from "../../../components/forms/ImageDragDrop"
import OrganizationsTable from "../../../components/tables/OrganizationsTable"
import ProfileImage from "../../../components/images/ProfileImage"
import validateInput from "../../../utils/validate"
import { FaRegEyeSlash } from "react-icons/fa6"
import { managementCompanyService } from "../../../services/managementCompanyService"
import DynamicInput from "../../../components/forms/DynamicInput"

export default function UpdateProfile() {
  const deviceView = useDevice()
  const { id, roleId: currentUserRoleId } = useUser()
  const { showBoundary } = useErrorBoundary()
  const { id: currentId } = useParams()
  const queryClient = useQueryClient()
  const { data: user } = useQuery(["user", currentId], () => userService.getOne(Number(currentId)))
  const thisUser = user // different from user in useUser hook. This is the user being updated
  const formRef = useRef<HTMLFormElement>(null)
  if (isEmpty(thisUser)) {
    showBoundary("User not found")
    throw new Error("User not found")
  }

  const navigate = useNavigate()
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [activateUpload, setActivateUpload] = useState<boolean>(false)
  const [saveStatus, setSaveStatus] = useState<string | null>(null)
  const [deleteStatus, setDeleteStatus] = useState<string | null>(null)
  const [userProfile, setUserProfile] = useState<User>(thisUser)
  const [updatePassword, setUpdatePassword] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string>("")
  const [newConfirmPassword, setNewConfirmPassword] = useState<string>("")
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [preview, setPreview] = useState<string>("")
  const [selectedOrganizationIds, setSelectedOrganizationIds] = useState<number[]>(thisUser?.userToOrganization?.map((org: UserToOrganization) => org.organizationId) || [])
  const [selectedLocationIds, setSelectedLocationsIds] = useState<number[]>(thisUser?.userToLocation?.map((loc: UserToLocation) => loc.locationId) || [])
  const [roleId, setRoleId] = useState<number>(thisUser.roleId || 0)
  const { data: locationData } = useQuery(["locations"], () => locationService.getAllByUser(id))
  const { data: organizationData } = useQuery(["organizations"], () => organizationService.getAllByUser(id))
  const { data: managementCompany } = useQuery(["managementCompany", thisUser], () => managementCompanyService.getByUser(thisUser.id))
  const managementCompanyId = useRef(managementCompany?.id)
  // create a roles list to be used in the CustomSelect component. The rolesList should only allow roles that are less than the current user's role
  let filteredLocations = managementCompanyId.current ? locationData?.filter((location) => location?.managementCompanyId === managementCompanyId.current) : locationData
  let filteredOrganizations = managementCompanyId.current ? organizationData?.filter((org) => org?.managementCompanyId === managementCompanyId.current) : organizationData

  const rolesList = Object.keys(roles)
    .filter((role) => currentUserRoleId && +role < currentUserRoleId && +role < ADMIN)
    .map((role) => {
      return { value: role, label: roles[+role], name: roles[+role] }
    })

  const handleUpdateProfile = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    let userInfoToUpdate: Partial<User> = { id: userProfile.id, firstName: userProfile.firstName, lastName: userProfile.lastName, phone: userProfile.phone, email: userProfile.email, roleId: roleId, managementCompanyId: managementCompanyId.current }
    if (updatePassword) {
      if (validatePasswords(newPassword, newConfirmPassword)) {
        userInfoToUpdate = { ...userInfoToUpdate, password: newPassword }
      } else {
        return alert("Passwords do not match")
      }
    }
    setSaveStatus("Saving...")
    let updatedUser: Partial<User> | null = null
    try {
      updatedUser = await userService.update(userInfoToUpdate, selectedOrganizationIds, selectedLocationIds, imageFile)
    } catch (error) {
      showBoundary(error)
    }
    if (updatedUser) {
      setSaveStatus("Saved")
      setTimeout(() => {
        setSaveStatus(null)
        queryClient.invalidateQueries()
        queryClient.refetchQueries()
        navigate(`/app/users/${updatedUser?.id}`, { state: { user: { ...updatedUser } as User & Required<Pick<User, "userToLocation" | "userToOrganization" | "createdAt">> } })
      }, 1000)
    } else {
      setSaveStatus("Error")
      setTimeout(() => {
        setSaveStatus(null)
      }, 1500)
    }
  }

  const toggleDeleteModal = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setShowDeleteModal(!showDeleteModal)
  }

  const handleDeleteProfile = async () => {
    try {
      setDeleteStatus("Deleting...")
      const deletedUser = await userService.delete(thisUser.id)

      if (deletedUser) {
        setDeleteStatus("Deleted")
        setTimeout(() => {
          setDeleteStatus(null)
          toggleDeleteModal({ preventDefault: () => {} })
          queryClient.invalidateQueries(["users"])
          navigate("/app/")
        }, 1000)
      } else {
        setDeleteStatus("Error")
        setTimeout(() => {
          setDeleteStatus(null)
        }, 1500)
      }
    } catch (error) {
      showBoundary(error)
    }
  }

  const handleLocationSelection = (locations: MultiValue<Location>) => {
    const locationIds = locations.map((location): number => location.id!!)
    setSelectedLocationsIds(locationIds)
    setSelectedOrganizationIds([])
  }

  const handleOrganizationSelect = (organizations: MultiValue<Organization>) => {
    const organizationIds = organizations.map((organization): number => organization.id!!)
    setSelectedOrganizationIds(organizationIds)
    setSelectedLocationsIds([])
  }

  const handleRoleSelection = (role: { label: string; name: string; value: number }) => {
    if(+role.value > AREA_MANAGER ) {
      setSelectedOrganizationIds([])
      setSelectedLocationsIds([])
    }
    setRoleId(+role.value)
  }

  const handleChange = (e: { target: { validity: any; setCustomValidity(arg0: string): unknown; name: string; value: string } }) => {
    if (e.target.name === "phone") {
      const phoneNumber = phoneNumberAutoFormat(e.target.value)
      validateInput(e.target, "Please match the pattern: 555-555-5555.")
      setUserProfile({ ...userProfile, [e.target.name]: phoneNumber })
    } else if (e.target.name === "password") {
      setNewPassword(e.target.value)
    } else if (e.target.name === "confirmPassword") {
      setNewConfirmPassword(e.target.value)
    } else {
      setUserProfile({ ...userProfile, [e.target.name]: e.target.value })
    }
  }

  const handleNewImage = (file: File | null) => {
    setPreview(URL.createObjectURL(file!))
    setImageFile(file)
  }

  return (
    <div className="w-full flex flex-col items-center">
      <ConfirmationModal isOpen={showDeleteModal} title={"Delete Profile?"} message={"All data associated with this user will be deleted forever!"} onConfirm={handleDeleteProfile} onCancel={toggleDeleteModal} saveStatus={deleteStatus} />
      <div className="w-full md:max-w-3xl flex flex-wrap bg-secondary-extra-light p-2 sm:rounded-lg shadow-lg h-min ">
        <div className="bg-primary-dark text-white p-4 rounded-lg justify-self-start w-full md:w-56 lg:w-64 m-2 ">
          <div className="relative w-fit m-auto hover:cursor-pointer" onClick={() => setActivateUpload(!activateUpload)}>
            <ImageDragDrop setImageState={handleNewImage} className="hidden" activateUpload={activateUpload} />
            {preview ? <img src={preview} alt="User Profile" className="w-44 h-44 lg:w-44 lg:h-44 rounded-full mx-auto border-4 border-secondary-extra-light" /> : <ProfileImage user={user} />}

            {!thisUser.photoUrl && (
              <div className=" absolute top-10 right-10">
                <FaPlus />
              </div>
            )}
          </div>
          <h2 className="text-xl mt-4 text-center">{`${thisUser.firstName} ${thisUser.lastName}`}</h2>
          {managementCompany && currentUserRoleId && currentUserRoleId > OWNER && <p className="text-center">Company: {managementCompany.name}</p>}
          <p className="text-center">ID: {thisUser.id}</p>
          <p className="text-center">Role: {thisUser.roleName || (thisUser.roleId && roles[thisUser.roleId])}</p>
          <p className="text-center">Start Date: {new Date(thisUser.createdAt).toLocaleDateString()}</p>
        </div>
        <div className="bg-primary-dark text-white p-4 rounded-lg justify-self-start m-2 max-w-full flex-1 whitespace-nowrap relative">
          <form onSubmit={handleUpdateProfile} ref={formRef}>
            <div className="absolute top-3 right-3">
              <div className="w-100 flex flex-col justify-end">
                <SaveButtonWithStatus saveStatus={saveStatus} handleSave={() => formRef.current?.onsubmit} text="Save" />
                {currentUserRoleId && user.roleId && currentUserRoleId > user.roleId && (
                  <button className="bg-primary block mt-2 text-white p-2 rounded-lg hover:shadow-right-lg transform active:translate-y-1 active:scale-[.99]  active:shadow-none font-medium" onClick={toggleDeleteModal}>
                    Delete
                  </button>
                )}
              </div>
            </div>
            <div>
              <strong>First Name: </strong>
              <DynamicInput className="m-2 rounded-md bg-secondary-extra-light text-black px-2 min-w-48" type="text" value={userProfile.firstName || ""} name="firstName" onChange={handleChange} />
            </div>
            <div>
              <strong>Last Name: </strong>
              <DynamicInput
                className="m-2 rounded-md bg-secondary-extra-light text-black px-2 min-w-48"
                type="text"
                value={userProfile.lastName || ""}
                name="lastName"
                onChange={handleChange}
                style={{ width: (userProfile.lastName?.length || 0) * 9.7 + 16, maxWidth: deviceView < SMALL_TABLET ? 190 : 300, minWidth: 50 }}
              />
            </div>
            <div>
              <strong>Phone: </strong>
              <DynamicInput className={`m-2 rounded-md bg-secondary-extra-light text-black px-2 w-36`} type="tel" value={phoneNumberAutoFormat(userProfile.phone || "")} name="phone" onChange={handleChange} pattern={"^\\d{3}-\\d{3}-\\d{4}$"} maxLength={12} isRequired={true} />
            </div>
            <div>
              <strong>Email: </strong>
              <DynamicInput className="m-2 rounded-md bg-secondary-extra-light text-black px-2 min-w-44" type="email" value={userProfile.email || ""} name="email" isRequired={true} onChange={handleChange} />
            </div>
            {(thisUser.userToLocation && roleId === ASSISTANT_MANAGER) || (roleId === MANAGER && locationData && currentUserRoleId && user.roleId && currentUserRoleId > user.roleId) ? (
              <div>
                <strong>Locations (Primary First): </strong>
                <CustomSelect
                  list={filteredLocations as Location[]}
                  placeholder="Select location(s)"
                  isMulti
                  className="rounded-lg"
                  styles={{ background: "#daf1ff" }}
                  defaultValues={thisUser.userToLocation.map((loc: UserToLocation) => loc.locationId)}
                  handleSelection={handleLocationSelection}
                  required={true}
                />
              </div>
            ) : null}
            {(thisUser.userToOrganization && roleId === AREA_MANAGER && organizationData && currentUserRoleId && roleId && currentUserRoleId > user.roleId) || roleId === AREA_MANAGER ? (
              <div>
                <strong>Areas:</strong>
                <CustomSelect
                  list={filteredOrganizations as Organization[]}
                  placeholder="Select areas(s)"
                  isMulti
                  className="rounded-lg"
                  styles={{ background: "#daf1ff" }}
                  defaultValues={thisUser.userToOrganization.map((org: UserToOrganization) => org.organizationId)}
                  handleSelection={handleOrganizationSelect}
                  required={true}
                />
              </div>
            ) : null}

            <button
              className="bg-primary block mt-2 text-white p-2 rounded-lg hover:shadow-right-lg transform active:translate-y-1 active:scale-[.99]  active:shadow-none font-medium mb-2"
              onClick={(e) => {
                e.preventDefault()
                setUpdatePassword(!updatePassword)
              }}
            >
              {updatePassword ? "Cancel Update" : "Update Password"}
            </button>
            {updatePassword && (
              <>
                <p className="flex items-center">
                  <strong>New Password</strong>
                  <DynamicInput className="m-2 rounded-md bg-secondary-extra-light text-black px-2 min-w-48" type={showPassword ? "text" : "password"} value={newPassword || ""} name="password" minLength={8} maxLength={50} onChange={handleChange} />
                  {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} />}
                </p>

                <p className="flex items-center">
                  <strong>Confirm Password</strong>
                  <DynamicInput className="m-2 rounded-md bg-secondary-extra-light text-black px-2 min-w-48" type={showPassword ? "text" : "password"} value={newConfirmPassword || ""} name="confirmPassword" minLength={8} onChange={handleChange} />
                </p>
              </>
            )}

            <p className="pb-2">
              <strong>Role:</strong>
              {/* Make sure the current user making changes is a company manager or above and they aren't changing their own role. */}
              {currentUserRoleId && currentUserRoleId > AREA_MANAGER && id !== thisUser.id ? (
                <CustomSelect list={rolesList} className="rounded-lg" styles={{ background: "#daf1ff" }} defaultValues={thisUser.roleId ? [roles[thisUser.roleId]] : []} handleSelection={handleRoleSelection} required={true} />
              ) : (
                thisUser.roleName || (thisUser.roleId && roles[thisUser.roleId])
              )}
            </p>
            <p>
              <strong>Start Date: </strong>
              {new Date(thisUser.createdAt).toLocaleDateString()}
            </p>
          </form>
        </div>
      </div>
      <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
        <UsersTable userProfileId={thisUser.id} />
      </div>
      <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
        <LocationsTable userProfileId={thisUser.id} />
      </div>
      {user.roleId && user.roleId > MANAGER && (
        <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
          <OrganizationsTable userProfileId={user.id} />
        </div>
      )}
    </div>
  )
}
