import { Link, useParams } from "react-router-dom"
import UsersTable from "../../components/tables/UsersTable"
import { MANAGER, OWNER, roles } from "../../utils/roles"
import LocationsTable from "../../components/tables/LocationsTable"
import { phoneNumberAutoFormat } from "../../utils/helper"
import OrganizationsTable from "../../components/tables/OrganizationsTable"
import ProfileImage from "../../components/images/ProfileImage"
import { useContext, useState } from "react"
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6"
import DataContext from "../../context/dataProvider"
import { useQuery } from "@tanstack/react-query"
import { userService } from "../../services/userService"
import { managementCompanyService } from "../../services/managementCompanyService"

export default function UserProfile() {
  const [showPin, setShowPin] = useState<boolean>(false)
  // get current user logged in user
  const { currentUser } = useContext(DataContext)
  const { id } = useParams()

  const { data: user} = useQuery(["user", id], () => userService.getOne(Number(id)), {
    enabled: id !== undefined,
  })
  const { data: managementCompany } = useQuery(["managementCompany", user], () => managementCompanyService.getByUser(user.id))

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full md:max-w-3xl flex flex-wrap bg-secondary-extra-light p-2 sm:rounded-lg shadow-lg h-min ">
        <div className="bg-primary-dark text-white p-4 rounded-lg justify-self-start w-full md:w-64 lg:w-64 m-2 ">
          <div className="relative w-fit m-auto hover:cursor-pointer">
            <ProfileImage user={user} />
          </div>
          <h2 className="text-xl mt-4 text-center">{`${user.firstName} ${user.lastName}`}</h2>
          {managementCompany && currentUser.roleId && currentUser.roleId > OWNER && <p className="text-center">Company: {managementCompany.name}</p>}
          <p className="text-center">ID: {user.id}</p>
          <p className="text-center">Role: {user.roleName || (user.roleId && roles[user.roleId])}</p>
          <p className="text-center">Start Date: {new Date(user.createdAt).toLocaleDateString()}</p>
        </div>
        <div className="bg-primary-dark text-white p-4 rounded-lg justify-self-start m-2 max-w-full flex-1 whitespace-nowrap relative">
          {/* If this user is at the same role level as the logged in user, don't show the update link */}
          {((currentUser.roleId && user.roleId && currentUser.roleId > user.roleId) || (currentUser.id === user.id)) && (
            <Link to={`../users/update/${user.id}`} state={{ user }}>
              <button className="rounded-lg p-2 text-white text-center bg-primary border-sedondary absolute top-3 right-3  hover:border-2 hover:border-primary-light">Update</button>
            </Link>
          )}
          <p>
            <strong>Phone: </strong>
            <a href={`tel:${user.phone}`} className="text-blue-200 font-bold hover:underline">
              {phoneNumberAutoFormat(user.phone || "")}
            </a>
          </p>
          <p>
            <strong>Email: </strong>
            <a href={`mailto:${user.email}`} className="text-blue-200 font-bold hover:underline">
              {user.email}
            </a>
          </p>
          {user.userToLocation?.length > 0 && (
            <p>
              <strong>Primary Location: </strong>
              {user.userToLocation.find((uTl: { locationId: any }) => uTl.locationId === user.defaultLocationId)?.location.name}
            </p>
          )}
          <p>
            <strong>Role:</strong> {user.roleName || (user.roleId && roles[user.roleId])}
          </p>
          <p>
            <strong>Start Date: </strong>
            {new Date(user.createdAt).toLocaleDateString()}
          </p>
          <div className="flex items-center"> 
            <strong className="pr-2">Pin: </strong>
            {showPin ? (
              <>
                <span className="pr-2">{user.pin || null}</span>

                <FaRegEye onClick={() => setShowPin(false)} />
              </>
            ) : (
              <>
                <span className="pr-2 flex items-baseline h-4 text-lg">******</span>
                <FaRegEyeSlash onClick={() => setShowPin(true)} />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
        <UsersTable userProfileId={user.id} />
      </div>
      <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
        <LocationsTable userProfileId={user.id} />
      </div>
      {user.roleId && user.roleId > MANAGER && (
        <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
          <OrganizationsTable userProfileId={user.id} />
        </div>
      )}
    </div>
  )
}
