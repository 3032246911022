import React, { useState, useContext, useCallback, useMemo, useEffect } from "react"
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api"
import { Incident } from "../../Interfaces"
import Switch from "@mui/material/Switch"
import PopupModal from "../modals/PopupModal"
import TextareaAutosize from "@mui/material/TextareaAutosize"
import { incidentService } from "../../services/IncidentService"
import { CircularProgress } from "@mui/material"
import DataContext from "../../context/dataProvider"

interface IncidentsViewProps {
  locationId: number
  incidents: Incident[]
  showUnresolvedOnly: boolean
  setShowUnresolvedOnly: (value: boolean) => void
  coordinates: { lat: number; lng: number }
  zoom: number
}

const IncidentsView: React.FC<IncidentsViewProps> = ({
  locationId,
  incidents,
  showUnresolvedOnly,
  setShowUnresolvedOnly,
  coordinates,
  zoom
}) => {
  const { currentUser } = useContext(DataContext)
  const [selectedIncident, setSelectedIncident] = useState<Incident | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newNote, setNewNote] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [hoveredIncidentId, setHoveredIncidentId] = useState<number | null>(null);
  const [mapKey, setMapKey] = useState(0);
  const [imageLoadingStates, setImageLoadingStates] = useState<boolean[]>(selectedIncident?.images?.map(() => true) || []);
  const [isResolved, setIsResolved] = useState<boolean>(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ['places'],
  })

  const handleIncidentClick = (incident: Incident) => {
    setSelectedIncident(incident)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedIncident(null)
    setNewNote("")
  }

  const handleAddNote = async () => {
    if (!selectedIncident || !newNote.trim() || !currentUser) return;

    setIsLoading(true);
    try {
      await incidentService.addNote({ incidentId: selectedIncident.id, note: newNote, userId: currentUser.id });
      const updatedIncident = await incidentService.getOne(selectedIncident.id);
      setSelectedIncident((prevIncident) => {
        if (!prevIncident) return null;
        return {
          ...prevIncident,
          notes: updatedIncident.notes, // Use the notes from the updated incident
        };
      });
      setNewNote("");
      handleCloseModal(); // Close the modal after adding the note
    } catch (error) {
      console.error("Error adding note:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const mapRef = useCallback((node: google.maps.Map | null) => {
    if (node !== null) {
      // You can perform any necessary operations on the map instance here
    }
  }, [])

  useEffect(() => {
    setMapKey((prevKey) => prevKey + 1);
  }, [hoveredIncidentId]);

  useEffect(() => {
    if (selectedIncident) {
      setIsResolved(!!selectedIncident.resolvedAt);
    }
  }, [selectedIncident]);

  const handleToggleResolved = async () => {
    if (!selectedIncident) return;

    try {
      const updatedIncident = await incidentService.resolve({
        incidentId: selectedIncident.id,
        resolved: !isResolved,
        resolvedAt: !isResolved ? new Date().toISOString() : null
      });

      setIsResolved(!isResolved);
      setSelectedIncident((prevIncident) => {
        if (!prevIncident) return null;
        return {
          ...updatedIncident,
          notes: prevIncident.notes, // Retain the current notes
          images: prevIncident.images, // Retain the current images
        };
      });
    } catch (error) {
      console.error("Error updating resolved status:", error);
    }
  };

  console.log('incidents', incidents)

  const markers = useMemo(() => {
    if (!isLoaded) return [];
    return incidents.map((incident) => (
      <MarkerF
        key={incident.id}
        position={{ lat: incident.latitude, lng: incident.longitude }}
        onClick={() => handleIncidentClick(incident)}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: hoveredIncidentId === incident.id ? 12 : 6,
          fillColor: incident.id === hoveredIncidentId ? '#FF0000' : '#0000FF',
          fillOpacity: 0.8,
          strokeWeight: 2,
          strokeColor: incident.id === hoveredIncidentId ? '#FF0000' : '#0000FF',
        }}
      />
    ));
  }, [incidents, hoveredIncidentId, handleIncidentClick, isLoaded]);

  const handleImageLoad = (index: number) => {
    setImageLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={coordinates}
          zoom={zoom}
          onLoad={mapRef}
          key={mapKey}
          options={{
            mapTypeControl: false, // Disable the map type selector
            mapTypeId: google.maps.MapTypeId.SATELLITE
          }}
        >
          {markers}
        </GoogleMap>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="flex justify-between items-center p-4">
          <h2 className="text-xl font-bold">Incidents</h2>
          <div className="flex items-center">
            <span className="mr-2">Show Unresolved Only</span>
            <Switch
              checked={showUnresolvedOnly}
              onChange={(e) => setShowUnresolvedOnly(e.target.checked)}
              color="primary"
            />
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {incidents.map((incident) => (
            <li
              key={incident.id}
              className="p-4 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleIncidentClick(incident)}
            >
              <div className="flex justify-between items-center p-4">
                <div className="flex-1">
                  <h3 className="text-lg font-semibold">{incident.notes.reverse()[0].note}</h3>
                </div>
                <div className="flex-1 text-sm text-gray-600">
                  <p>{new Date(incident.createdAt).toLocaleDateString()}</p>
                </div>
                <div className="flex-1 mt-1">
                  <p>
                    {incident.resolvedAt
                      ? `Resolved at: ${new Date(incident.resolvedAt).toLocaleDateString()}`
                      : "Unresolved"}
                  </p>
                </div>
                <div className="flex-1 text-sm text-gray-600">
                  <p>Images: {incident.images.length}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <PopupModal isOpen={isModalOpen} onCancel={handleCloseModal} className="w-3/4 max-w-6xl h-[90vh]">
        {selectedIncident && (
          <div className="flex h-full p-4 overflow-hidden">
            <div className="w-3/5 pr-4 flex flex-col h-full">
              <h2 className="text-xl font-bold mb-4">Incident {selectedIncident.id}</h2>
              <div className="w-full mt-auto">
                <Switch
                  checked={isResolved}
                  onChange={handleToggleResolved}
                  color="primary"
                  name="resolvedSwitch"
                  inputProps={{ 'aria-label': 'Resolve Incident' }}
                />Resolved
              </div>
              <div className="flex-grow overflow-y-auto mb-4">
                {selectedIncident?.notes?.map((note, index) => (
                  <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
                    <p>{note.note}</p>
                    <p className="text-xs text-gray-500">{new Date(note.createdAt).toLocaleString()}</p>
                  </div>
                ))}
              </div>
              <div className="mt-auto">
                <TextareaAutosize
                  minRows={2}
                  placeholder="Add a new note..."
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  className="w-full p-2 border rounded"
                />
                <button
                  onClick={handleAddNote}
                  disabled={isLoading}
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  {isLoading ? "Adding..." : "Add Note"}
                </button>
              </div>
            </div>
            <div className="w-2/5 pl-4 h-full overflow-y-auto">
              <h3 className="text-lg font-semibold mb-4">Images</h3>
              <div className="flex flex-col items-center">
                {selectedIncident?.images?.map((image, index) => (
                  <div key={index} className="mb-4 w-full">
                    {imageLoadingStates[index] && (
                      <div className="w-full h-64 flex items-center justify-center bg-gray-200">
                        <CircularProgress />
                      </div>
                    )}
                    <img
                      src={image.imageUrl}
                      alt={`Incident ${selectedIncident.id} - Image ${index + 1}`}
                      className={`w-full h-auto object-contain ${imageLoadingStates[index] ? 'hidden' : ''}`}
                      onLoad={() => handleImageLoad(index)}
                      onError={() => handleImageLoad(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </PopupModal>
    </div>
  )
}

export default IncidentsView
