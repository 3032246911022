import { createContext, useState } from "react"
import { Children, Location, DataContextProps, User } from "../Interfaces"

const DataContext = createContext<DataContextProps>({
  currentUser: {} as User,
  setCurrentUser: () => {},
  users: [],
  setUsers: () => {},
  currentLocation: {} as Location,
  setCurrentLocation: () => {},
})

export const DataProvider = ({ children }: Children) => {
  const [currentUser, setCurrentUser] = useState<User>({} as User)
  const [users, setUsers] = useState<User[]>([])
  const [currentLocation, setCurrentLocation] = useState<Location>({} as Location)


  return <DataContext.Provider value={{ currentUser, setCurrentUser, users, setUsers, currentLocation, setCurrentLocation }}>{children}</DataContext.Provider>
}

export default DataContext
