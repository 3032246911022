import axios from "axios"
import { ExtendedWalkReport, NewWalk, Walk } from "../Interfaces";
import { showErroPage } from "../utils/helper";
class WalkService {
  private baseUrl = process.env.REACT_APP_API_URL + "/walk";

  public create = async (walkInfo: NewWalk,) => {
    try {
      if(!walkInfo || !walkInfo.locationId || !walkInfo.markers.length) throw new Error("Missing walk info")
      const response = await axios.post(
        `${this.baseUrl}`,
        walkInfo,
      );
      return response.data
    } catch (error:any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
      } else {
        throw Error(error)
      }
    }
  }

  public getOne = async (walkId: number) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/${walkId}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public getOneReport = async (walkReportId: number): Promise<ExtendedWalkReport> => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/walk-report/${walkReportId}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
        throw new Error("axios error")
      } else {
        console.error(error);
      }
    }
    return {} as ExtendedWalkReport
  }

  public getAll = async (locationId: number | null) => {
    if(locationId === null) return []
    try {
      const response = await axios.get(
        `${this.baseUrl}/all/${locationId}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public getSomeReportsByLocation = async (locationId: number, limit: number, offset: number, searchTerm?: string) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/some/by-location/${locationId}?limit=${limit}&offset=${offset}&searchTerm=${searchTerm || ""}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw new Error("axios error")
      } else {
        console.error(error);
        showErroPage()
      }
    }
  }
  
  public getViewableMarkerImage = async (walkReportId: number, markerId: number, key: string) => {
    try {
      const { data: signedUrl } = await axios.get(
        `${this.baseUrl}/photo/${walkReportId}/${markerId}/${key}`,
      )
      return signedUrl;
    } catch (error) {
      return error as Error;
    }
  }

  public update = async (walkInfo: Walk) => {
    try {
      const response = await axios.put(
        `${this.baseUrl}`,
        walkInfo
      );
      return response.data
    } catch (error:any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
      } else {
        throw Error(error)
      }
    }
  }

  public delete = async (walkId: number) => {
    try {
      const response = await axios.delete(
        `${this.baseUrl}/${walkId}`
      );
      return response.data
    } catch (error:any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
      } else {
        throw Error(error)
      }
    }
  }
}

export const walkService = new WalkService()