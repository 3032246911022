import { Menu, X } from "lucide-react"
import React, { useState, useRef } from "react"
import { Link } from "react-router-dom"

const HomeNavbar = ({demo, about, pricing, faq, whyStorageWalk}: any) => {

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  
  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen)
  }

  const handleMobileClick = (item: any) => {
    item.link.current?.scrollIntoView({behavior: 'smooth'});
    toggleNavbar();
  }
  const navLinks = [
    {
      title: 'WHY STORAGE WALK',
      link: whyStorageWalk
    },
    /*{
      title: 'DEMO',
      link: demo
    },*/
    {
      title: 'ABOUT',
      link: about
    },
    {
      title: 'PRICING',
      link: pricing
    },
    /*{
      title: 'FAQ',
      link: faq
    }*/
  ]

  return (
    <nav className="fixed top-0 py-3 z-50 bg-white flex items-center justify-center h-16 w-full">
      <div className=" mx-auto relative text-sm w-full">
        <div className="flex px-4 mx-auto relative lg:text-lg md:text-base xs:text-sm justify-center">
            <ul className="hidden lg:flex lg:space-x-24 md:flex xs:space-x-4 md:space-x-14 items-center">
              {navLinks.map((item, index) => (
                <li> 
                  <button className="hover:text-gray-400 font-bold" onClick={() => {item.link.current?.scrollIntoView({behavior: 'smooth'})}}> 
                    {item.title} 
                  </button> 
                </li>
              ))}
              <Link
                to="/login" 
                className="py-2 px-4 flex text-sm justify-center align-middle rounded-md text-white bg-[#FF5C36] hover:bg-[#0C233C] font-bold"
              > 
                Sign In 
              </Link>
            </ul>
        </div>
        <div className="md:hidden flex flex-row justify-between items-center mx-3">
          <Link
            to="/login" 
            className="py-2 px-4 flex text-sm justify-center align-middle rounded-md text-white bg-[#FF5C36] hover:bg-[#0C233C] z-50" 
          > 
            Sign In 
          </Link>
          <button className="flex justify-center z-50" onClick={toggleNavbar}>
              { mobileDrawerOpen ? <X/> : <Menu/> }
          </button>
          {mobileDrawerOpen && (
          <div className="fixed right-0 mt-52 z-20 bg-white w-full flex p-6 flex-col justify-end items-center lg:hidden">
              <ul className="flex flex-col items-center">
                  {navLinks.map((item, index) => (
                      <li className="py-2" key={index}>
                          <button onClick={() => handleMobileClick(item)}>{item.title}</button>
                      </li>
                  ))}
              </ul>
          </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export default HomeNavbar