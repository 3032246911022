type Props = {
  itemsPerPage: number
  setItemsPerPage: (itemsPerPage: number) => void
}
export default function ItemsPerPageSelector({ itemsPerPage, setItemsPerPage }: Props) {
  return (
    <div>
      <select name="itemsPerPage" className="border rounded-md" value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))}>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
      </select>
    </div>
  )
}
