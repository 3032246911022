import React from 'react'

const features = [
    {
        'title': 'Effortless Monitoring',
        'description': 'Use our intuitive app to conduct and record your morning and evening facility walks. No more cumbersome paper trails or disjointed notes; everything you need is in one place.'
    },
    {
        'title': 'Real-Time GPS Tracking',
        'description': 'Our state-of-the-art GPS technology combined with seamless AI tracking confirms your walks, ensuring accountability and providing an accurate map of covered areas.'
    },
    {
        'title': 'AI-Assisted Issue Detection',
        'description': 'Identify potential issues like missing locks or maintenance needs with AI assistance. Our technology helps in recognizing irregularities, ensuring nothing slips through the cracks.'
    },
    {
        'title': 'Photo Documentation',
        'description': 'Snap pictures of any irregularities or points of interest. Each photo is geo-tagged and time-stamped, providing visual evidence and context to any reported issue.'
    },
    {
        'title': 'Instant Reporting',
        'description': 'All data from walks, including notes and photos, instantly syncs to our secure cloud. Facility owners can access real-time reports, review progress, and address issues promptly from anywhere in the world.'
    },
    {
        'title': 'Custom Alerts',
        'description': 'Use our intuitive app to conduct and record your morning and evening facility walks. No more cumbersome paper trails or disjointed notes; everything you need is in one place.'
    },
    {
        'title': 'Effortless Monitoring',
        'description': "Set up custom notifications for specific issues or when walks are missed, ensuring that you're always in the loop."
    }
]
const WhyStorageWalk = ({whyStorageWalk}: any) => {
  return (
    <div ref={whyStorageWalk} className="w-full mx-auto py-20 px-6" style={{backgroundColor: "#F0E3E0"}}>
        <div className="flex flex-col">
            <div className="flex flex-col w-full">
                <div className="text-3xl font-bold lg:text-4xl flex justify-center py-4" style={{color: "#0C233C"}}>
                    Why Storage Walk?
                </div>
                <div className='flex flex-row flex-wrap justify-center'>
                    {features.map((item, index) => (
                        <div className="flex flex-col text-center p-5 sm:w-1/2 lg:w-1/4">
                            <div className='w-full flex  items-center h-56 sm:h-64 md:h-72 rounded-md p-3 flex-col' style={{backgroundColor: '#FF5C36'}} >
                                    <div className='text-white text-lg font-semibold flex pt-5'>
                                        {item.title}
                                    </div>
                                    <div className='flex h-full items-center'>
                                        <p className='text-white text-sm px-2'>
                                            {item.description}
                                        </p>
                                    </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhyStorageWalk