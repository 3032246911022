import { useContext, useEffect, useState } from "react"
import { FaAnglesLeft, FaHouse, FaLocationCrosshairs, FaUserPlus } from "react-icons/fa6"
import { BsBuildingAdd } from "react-icons/bs"
import { AiOutlineAppstore, AiOutlineAppstoreAdd } from "react-icons/ai"
import { BiLocationPlus } from "react-icons/bi"
import { Navigate } from "react-router-dom"
import UiContext from "../../context/uiProvider"
// import SmallLogo from "../../assets/site-walk-low-resolution-logo-white-on-transparent-background.png"
import SmallLogo from "../../assets/logo-white-no-background.png"
import useUser from "../../hooks/useUser"
import { MANAGER, OWNER } from "../../utils/roles"
import SidebarLink from "./SidebarLink"
import { DESKTOP, LARGE_DESKTOP, LARGE_TABLET, MOBILE, SMALL_TABLET, TABLET } from "../../utils/screenSize"
import { FaUser } from "react-icons/fa"
import Tooltip from "../tooltips/Tooltip"

export default function Sidebar() {
  const { sidebarOpen: open, setSidebarOpen: setOpen, deviceView, setDeviceView } = useContext(UiContext)
  const { roleId } = useUser()
  const [sidebarWidth, setSidebarWidth] = useState("w-20")

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1440) {
        setDeviceView(LARGE_DESKTOP)
        setSidebarWidth("w-64")
      } else if (window.innerWidth >= 1280) {
        setDeviceView(DESKTOP)
        setSidebarWidth("w-64")
      } else if (window.innerWidth > 1024) {
        setDeviceView(LARGE_TABLET)
        setSidebarWidth("w-64")
      } else if (window.innerWidth >= 768) {
        setDeviceView(TABLET)
        setSidebarWidth("w-56")
      } else {
        setDeviceView(MOBILE)
        setSidebarWidth("w-14")
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    // unmount
    return () => window.removeEventListener("resize", handleResize)
  }, [setDeviceView])

  // repeated class for transitions when expanding and collapsing sidebar
  const transitionLink = `transition-opacity ease-in ${open ? "duration-700 opacity-100 pl-3 text-lg" : "duration-100 opacity-0 w-0 p-0 absolute overflow-hidden"}`

  if (!roleId) return <Navigate to={"/login"} replace />

  return (
    <div className={`h-screen z-20 max-h-screen`}>
      <div className={` bg-primary-dark relative ease-in duration-300 text-white text-center h-full shadow-right-lg ${deviceView < SMALL_TABLET ? "w-14 pt-" : `${open ? sidebarWidth : "w-20"} text-xl`}`}>
        <div className={`w-full flex justify-center px-10 py-6 ${!open && "invisible"}`}>
          <img src={SmallLogo} alt="logo" />
        </div>
        {deviceView >= TABLET && <FaAnglesLeft className={`text-white z-30 text-3xl -right-3 absolute top-9 bg-primary-dark ease-in duration-300 rounded-full p-1 ${!open && "rotate-180"}`} onClick={() => setOpen(!open)} />}

        <SidebarLink to={"/app/" as "dashboard"}>
          <Tooltip text="Open Dashboard">
            <FaHouse className="text-3xl" />
          </Tooltip>
          {deviceView >= TABLET && (
            <Tooltip text="Drag tables to change order">
              <span className={transitionLink}>Dashboard</span>
            </Tooltip>
          )}
        </SidebarLink>
        {roleId > OWNER && (
          <SidebarLink to={"/app/addManagementCompany"}>
            <Tooltip text="Add Management Company">
              <BsBuildingAdd className="text-3xl" />
            </Tooltip>
            {deviceView >= TABLET && 
            <Tooltip text="Required for each company" className="flex">
            <span className={transitionLink + " pl-3 text-left text-lg"}>Add Management Company</span>
            </Tooltip>
            }
          </SidebarLink>
        )}
        {/* {roleId > OWNER && (
          <SidebarLink to={"/app/addOrganization"}>
            <Tooltip text="Create new area">
              <AiOutlineAppstoreAdd className="text-3xl" />
            </Tooltip>
            {deviceView >= TABLET && (
              <Tooltip text="Required for locations">
                <span className={transitionLink + " text-lg"}>Add Area</span>
              </Tooltip>
            )}
          </SidebarLink>
        )}
        {roleId > OWNER && (
          <SidebarLink to={"/app/addLocation"}>
            <BiLocationPlus className="text-3xl" />
            {deviceView >= TABLET && <span className={transitionLink}>Add Location</span>}
          </SidebarLink>
        )}
        {roleId > MANAGER && (
          <SidebarLink to={"/app/addUser"}>
            <Tooltip text="Create new user">
            <FaUserPlus className="text-3xl" />
            </Tooltip>
            {deviceView >= TABLET && <span className={transitionLink}>Create User</span>}
          </SidebarLink>
        )} */}
        {roleId > MANAGER && (
          <SidebarLink to={"/app/organizations"}>
            <AiOutlineAppstore className="text-3xl" />
            {deviceView >= TABLET && <span className={transitionLink + " pl-3 text-left text-lg"}>Areas</span>}
          </SidebarLink>
        )}
        <SidebarLink to={"/app/locations"}>
          <FaLocationCrosshairs className="text-3xl" />
          {deviceView >= TABLET && <span className={transitionLink}>Locations</span>}
        </SidebarLink>
        {roleId > MANAGER && (
          <SidebarLink to={"/app/users"}>
            <FaUser className="text-3xl" />
            {deviceView >= TABLET && <span className={transitionLink}>Users</span>}
          </SidebarLink>
        )}
      </div>
    </div>
  )
}
