import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import AuthContext from "../context/authProvider"
import { authService } from "../services/authService"
import { useQueryClient } from "@tanstack/react-query"

export default function PrivateRoutes() {
  const { auth } = useContext(AuthContext)
  const queryClient = useQueryClient()
  const decodedToken = auth.token ? JSON.parse(atob(auth.token.split(".")[1])) : null
  const expirationTime = decodedToken ? decodedToken.exp * 1000 : null

  if (!auth.token || !expirationTime || Date.now() >= expirationTime) {
    authService.logout()
    queryClient.clear()
    return <Navigate to={"/login"} replace />
  } else {
  return <Outlet />
}
}