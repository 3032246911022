import React, { useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const features = [
    { content: "GPS tracking with Daily Site Walks" },
    { content: "Reports sent to manager's computer" },
    { content: "Back up reports sent to the cloud" },
    { content: "Camera functions to take pictures of potential issues on site" },
    { content: "Be able to keep track of multiple sites" },
    { content: "Task management for employees" },
    { content: "Note taking functions for record keeping" },
    { content: "Ensures completed daily site inspections" },
    { content: "Pinpoints specific time frames when storage units may have been breached"},
    { content: "Provides an additional level of security for customer’s valuables"}

]

const PricingPlan = ({pricing}: any) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleEmailClick = () => {
    setShowPopup(true);
  }

  const handleClosePopup = () => {
    setShowPopup(false);
  }
  
  return (
    <div ref={pricing} className="w-full mx-auto py-20 px-6" style={{backgroundColor: "white"}}>
        <div className="flex flex-col items-center">
            <div className="flex w-3/4 flex-col">
                <div className="text-2xl lg:text-3xl text-black font-bold">
                    Contact us for Pricing
                </div>
                <hr className="h-px my-8 border-0" style={{backgroundColor: "#1D49E1"}}></hr>
                    <ul className="flex flex-row flex-wrap justify-between">
                        {features.map((item, index) => (
                            <li key={index} className="flex md:text-sm w-1/2 p-4 items-center xs:text-xs text-black">
                                <CheckCircleIcon fontSize="small" className="mr-2" style={{color: '#FF5C36'}}/>
                                {item.content}
                            </li>
                        ))}
                    </ul>
                <hr className="h-px my-8 border-0" style={{backgroundColor: "#1D49E1"}}></hr>
            </div>
            <button onClick={handleEmailClick} style={{backgroundColor: "#FF5C36"}} className="py-3 px-12 my-10 text-white rounded-md font-bold">
              Get Started Today
          </button>
          {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={handleClosePopup}>
            <div className="bg-white p-6 rounded-md shadow-lg xs:w-3/4 md:w-1/2" onClick={(e) => e.stopPropagation()}>
              <p className="text-center">Email Jim at <a href="mailto:jdaniels@aura-realestate.com" className="text-blue-600 hover:underline">jdaniels@aura-realestate.com</a> for more information</p>
            </div>
          </div>
          )}
        </div>
    </div>
  )
}

export default PricingPlan