import UsersTable from "../../components/tables/UsersTable"

export default function Users() {
 

  return (
    <div className="w-full min-h-full overflow-auto h-full">
      <UsersTable/>
    </div>
  )
}
