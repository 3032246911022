import { useState } from "react"
import { FieldState } from "../../Interfaces"
import Header from "../../components/forms/Header"
import { addManagementCompanyFields } from "../../constants/formFields"
import Input from "../../components/forms/Input"
import ImageDragDrop from "../../components/forms/ImageDragDrop"
import { managementCompanyService } from "../../services/managementCompanyService"
import { cleanPhoneNumber, phoneNumberAutoFormat } from "../../utils/helper"
import { useNavigate } from "react-router-dom"
import SaveButtonWithStatus from "../../components/general/SaveButtonWithStatus"

const fields = addManagementCompanyFields
let fieldsState: FieldState = {}
fields.forEach((field) => (fieldsState[field.name] = ""))

export default function AddManagementCompany() {
  const [addCompanyFormState, setAddCompanyFormState] = useState(fieldsState)
  const [companyLogo, setCompanyLogo] = useState<File | null>(null)
  const [saveStatus, setSaveStatus] = useState<string | null>(null)
  const navigate = useNavigate()

  const handleInputChange = (e: { target: { name: string; value: string } }) => {
    if (e.target.name === "phone") {
      const phoneNumber = phoneNumberAutoFormat(e.target.value)
      setAddCompanyFormState({ ...addCompanyFormState, [e.target.name]: phoneNumber })
    } else {
      setAddCompanyFormState({ ...addCompanyFormState, [e.target.name]: e.target.value })
    }
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSaveStatus("Saving...")
    // add company logo to addCompanyFormState
    const newCompanyInfo = { ...addCompanyFormState, phone: cleanPhoneNumber(addCompanyFormState.phone) }
    const managementCompany = await managementCompanyService.create(newCompanyInfo, companyLogo)
    // clear form
    if(managementCompany){
      setSaveStatus("Saved!")
      setTimeout(() => {
        setSaveStatus(null)
        setAddCompanyFormState(fieldsState)
        navigate("/app")
      }, 1000)
    } else {
      setSaveStatus("Error")
      setTimeout(() => {
        setSaveStatus(null)
      }, 1500)
    }   
  }

  return (
    <div className="w-full min-h-full flex justify-center">
      <section className="p-5 w-full lg:max-w-4xl md:p-5 md:shadow-xl md:rounded-xl bg-white max-h-screen overflow-auto">
        <Header heading="Add Management Company" paragraph="Management companies are required to add areas, locations, and users."/>
        <form className="mt-8 space-y-2" onSubmit={handleSubmit}>
        <div className="lg:flex lg:justify-evenly items-center">
            <section className="lg:w-full">
          <div>
            {fields.map((field) => (
              <Input
                className="bg-gray-50 border-gray-300"
                key={field.id}
                handleChange={handleInputChange}
                value={addCompanyFormState[field.id]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                pattern={field.pattern}
              />
            ))}
          </div>
          <div className="w-full"></div>
            </section>
          <section className="lg:w-full lg:pl-5 lg:m-auto">
            <ImageDragDrop setImageState={setCompanyLogo} title="Upload Logo"/>
          </section>
          </div>
          <SaveButtonWithStatus saveStatus={saveStatus} handleSave={handleSubmit} isForm={true} className="w-full" />
        </form>
      </section>
    </div>
  )
}
