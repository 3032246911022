import axios from "axios";
import { Organization } from "../Interfaces";
import { showErroPage, sortByAttribute } from "../utils/helper";

class OrganizationService {

  private baseUrl = process.env.REACT_APP_API_URL + "/organization";

  public create = async (organizationInfo: any, imageFile: File | null = null) => {
    try {
      const response = await axios.post(
        `${this.baseUrl}`,
        organizationInfo,
      );
      if (imageFile) await this.uploadLogo(imageFile, response.data.id, response.data.name)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
      return null
    }
  }

  public uploadLogo = async (imageFile: File, orgId: number, orgName: string) => {
    if (!imageFile) return;
    try {
      const { data: signedUrl } = await axios.get(
        `${this.baseUrl}/update/logo/${orgId}/${orgName}`,
      )
      if (imageFile.type !== "image/jpeg" && imageFile.type !== "image/png" && imageFile.type !== "image/jpg") throw new Error("Invalid file type")
      const response = await axios.put(
        signedUrl,
        imageFile,
        {
          headers: {
            "Content-Type": imageFile.type,
            "Authorization": undefined,
          }
        }
      );
      return response.data;
    } catch (error) {
      return error as Error;
    }
  };

  public getViewableLogo = async (orgId: number, orgName: string) => {
    try {
      const { data: signedUrl } = await axios.get(
        `${this.baseUrl}/logo/${orgId}/${orgName}`,
      )
      return signedUrl;
    } catch (error) {
      return error as Error;
    }
  }

  public getOne = async (id: number) => {
    if (!id) throw new Error("No id provided")
    try {
      const response = await axios.get(
        `${this.baseUrl}/${id}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public getManagementCompanyName = async (id: number) => {
    if (!id) throw new Error("No id provided")
    try {
      const response = await axios.get(
        `${this.baseUrl}/managementCompany-name/${id}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public getAllByUser = async (userId: number): Promise<Partial<Organization[]>> => {
    //TODO: this route will will check if user has permissions on the backend
    try {
      const response = await axios.get(
        `${this.baseUrl}/organizations/${userId}`
      )
      sortByAttribute(response.data, "name")
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        const message = error.response?.data.message
        // Do something with this error...
        throw new Error(message)
      } else {
        console.error(error)
        showErroPage()
        return []
      }
    }
  }

  public getAllByManagementCompany = async (managementCompanyId: number): Promise<Partial<Organization[]>> => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/all/by-management-company/${managementCompanyId}`
      )
      sortByAttribute(response.data, "name")
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        const message = error.response?.data.message
        // Do something with this error...
        throw new Error(message)
      } else {
        console.error(error)
        showErroPage()
        return []
      }
    }
  }

  public getSomeByUser = async (userId: number, limit: number, page: number, searchTerm?: string): Promise<{ organizations:Partial<Organization[]>, totalCount: number}> => {
    //TODO: this route will will check if user has permissions on the backend
    try {
      const response = await axios.get(
        `${this.baseUrl}/some/by-user/${userId}?limit=${limit}&offset=${page}&searchTerm=${searchTerm || ""}`
      )
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        const message = error.response?.data.message
        // Do something with this error...
        throw new Error(message)
      } else {
        console.error(error)
        showErroPage()
        return {organizations:[], totalCount:0}
      }
    }
  }

  public update = async (organizationInfo: any, imageFile: File | null = null) => {
    if (!organizationInfo) throw new Error("No area info provided")

    try {
      const response = await axios.put(
        `${this.baseUrl}`,
        organizationInfo
      )
      if (imageFile) await this.uploadLogo(imageFile, response.data.id, response.data.name)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
      return null
    }
  }

  public delete = async (id: number) => {
    if (!id) throw new Error("No id provided")
    try {
      const response = await axios.delete(
        `${this.baseUrl}/${id}`
      )
      return response.data
      // Do something with the response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }
}

export const organizationService = new OrganizationService()

