import Header from "../../../components/forms/Header"


export default function ResetEmailSentPage() {
  const currentEmailSource = "something@sitewalker.org"

  return (
    <section className="flex pt-6 md:pt-none md:items-center md:pb-44 justify-center md:w-full md:bg-gradient-to-r from-primary via-secondary to-secondary-light">
    <div className="md:w-2/4 lg:max-w-xl md:p-5 md:shadow-xl md:rounded-xl bg-white">
        <Header heading="Email Sent" paragraph="Please check your email" linkName="Login" linkUrl="/" />
        <p className="text-center text-sm text-gray-600 mt-5">A link to reset your password has been sent from {currentEmailSource}. Please check your email for details. Can't find our email? Please check your Spam or Junk folder just in case, and mark us as 'Not Spam' to avoid this in the future.</p>

      </div>
    </section>
  )
}
