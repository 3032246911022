import React from 'react'
import storageWalkLogo from "../../logo/white_storage_walk_logo.png"

const About = ({about}: any) => {
  return (
    <div ref={about} className="w-full mx-auto py-20 px-6" style={{backgroundColor: "#0C233C"}}>
      <div className="flex flex-col items-center">
          <div className="flex w-3/4 flex-col text-3xl md:text-4xl tracking-wide text-white font-bold">
              About
          </div>
          <p className="text-sm w-3/4 mt-6 text-white leading-6">
          The creation of the Storage Walk App came together out of a need to 
          provide security and transparency for site managers and employees to keep track of 
          daily walks and report issues seen on site. Join the Future of Facility Management. 
          Embrace a solution where technology meets practicality. Whether you own one facility or
          manage multiple sites, Storage Walk ensures peace of mind through transparency, efficiency, and control.
          <br/>
          <br/>
          Contact Us: Have questions? Our support team is here to help every step of the way. 
          </p>
          <div className="flex justify-start w-3/4 items-start">
            <img src={storageWalkLogo} alt="StorageWalk Logo" className="w-1/3" />
          </div>
      </div>
    </div>
  )
}

export default About