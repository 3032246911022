import axios from "axios"
import { createContext, useEffect, useState } from "react"
import { Children, UserAuth } from "../Interfaces"

interface AuthContext {
  auth: UserAuth
  setAuth?: (auth: UserAuth) => void
}

const AuthContext = createContext<AuthContext>({ auth: { token: localStorage.getItem("token"), user: {id:0}} })

export const AuthProvider = ({ children }: Children) => {
  const [auth, setAuth] = useState<UserAuth>({ token: localStorage.getItem("token"), user: JSON.parse(localStorage.getItem("user") || `{"id":"7"}`)})

  useEffect(() => {
    if (auth?.token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + auth.token
    } else {
      delete axios.defaults.headers.common["Authorization"]
      localStorage.removeItem("token")
      localStorage.removeItem("user")
    }
  }, [auth?.token])

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>
}

export default AuthContext
