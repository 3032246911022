import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Children } from "../../Interfaces"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { Suspense, useContext, useEffect } from "react"
import { navigation } from "../../utils/helper"
// import Loading from "../../components/loading/LoadingSkeleton"
import LoadingSpinTrail from "../../components/loading/LoadingSpinTrail"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "../../pages/errors/ErrorFallback"
import DataContext from "../../context/dataProvider"
import { useQuery } from "@tanstack/react-query"
import useUser from "../../hooks/useUser"
import { userService } from "../../services/userService"
import Footer from "../../components/footer"

export default function MainLayout() {
  // These are used to allow navigation and location to be used outside of a component (in the helper file and services for error handling)
  navigation.navigate = useNavigate()
  navigation.location = useLocation()
  const { id } = useUser()
  const { setCurrentUser } = useContext(DataContext)
  const { data } = useQuery(["user", id], () => userService.getOne(id), { cacheTime: 99999, staleTime: 99999 })
  useEffect(() => {
    setCurrentUser(data)
  }, [data])
  if (!data) return <LoadingSpinTrail />
  return (
    <div className="flex w-full max-h-full">
      <Navbar />
      <Sidebar />
      <div className="flex w-full justify-center z-0 mt-12 bg-secondary-light max-h-screen overflow-y-auto">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LoadingSpinTrail />}>
            <div className="flex flex-col items-center w-full md:p-6 justify-between" style={{}}>
              <Outlet />

              <Footer />
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  )
}
