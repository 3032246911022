import { Link } from "react-router-dom"

export default function Footer() {
  return (
    <div className="pt-4 w-full">
      <hr className=" border-secondary-extra-light border-[1px] w-full" />
      <footer className={`flex justify-self-end w-full self`}>
      {/* <footer className={`flex-grow flex w-full flex-col justify-end pt-1`}> */}
        <div className={`z-10 duration-300 ease-in w-full h-14 flex items-center justify-end  text-md  px-8 text-black`}>
          <Link to={"/terms-of-service"} className=" text-primary-dark px-2">
            Terms of Service
          </Link>
          <span className="text-secondary-extra-light">|</span>
          <Link to={"/privacy-policy"} className=" text-primary-dark px-2">
            Privacy Policy
          </Link>
        </div>
      </footer>
    </div>
  )
}
