import { useQuery } from "@tanstack/react-query"
import { userService } from "../../services/userService"
import { User } from "../../Interfaces"
import { useContext, useEffect, useState } from "react"
import { roles } from "../../utils/roles"
import UiContext from "../../context/uiProvider"
import { useParams } from "react-router-dom"
import { LARGE_DESKTOP, LARGE_TABLET, TABLET } from "../../utils/screenSize"
import DataContext from "../../context/dataProvider"
import Table from "./common/Table"

export default function OrganizationUsersTable({organizationId: orgId}: {organizationId: number}) {
  const { id } = useParams()
  const { deviceView } = useContext(UiContext)
  const { setUsers } = useContext(DataContext)
  const organizationId = Number(id) || orgId
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10) // can be changed to a user setting later
  const [totalUsers, setTotalUsers] = useState(0)
  const [searchTerm, setSearchTerm] = useState<string>("")

  const { data, isFetching } = useQuery<{ users: User[]; totalCount: number }>(["users", "organizationId" + organizationId, currentPage, limit, searchTerm], () => userService.getSomeByOrganization(organizationId, limit, currentPage - 1, searchTerm), {
    cacheTime: 5000
})

const [filteredData, setFilteredData] = useState<User[] | null | undefined>(null)

useEffect(() => {
  // set viewable users for current user. This can be used later to filter users by organization or location w/out making another api call
  if (data) {
    setUsers((prev) => [...prev, ...data.users])
    const filtered = data.users.map((user) => {
      user.roleName = roles[user.roleId || 1]
      user.userToLocation = user.userToLocation || []
      user.userToOrganization = user.userToOrganization || []
      return user
    })
    setFilteredData(filtered)
    setTotalUsers(data.totalCount)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [data])

  const headers = [
    { label: "First Name", key: "firstName", to: `/app/users`},
    { label: "Last Name", key: "lastName", to: `/app/users`},
  ]
  // Add two more columns on tablets and desktops
  if (deviceView >= TABLET) {
    headers.unshift({ label: "Id", key: "id", to: `/app/users` },)
    headers.push({ label: "Role", key: "roleName",  to: `/app/users`})
  }
  // Add three additional columns on desktops
  if (deviceView >= LARGE_TABLET) {
    headers.push({ label: "Phone", key: "phone", to: `/app/users` }, { label: "Email", key: "email", to: `/app/users` }, { label: "Primary Location", key: "defaultLocationId", to: `/app/users`})
  }

  // Add additional column on larger screen
  if (deviceView >= LARGE_DESKTOP) {
    // headers.push({ label: "Organizations", key: "userToOrganization" })
  }

  function handleSearch(query: string) {
    setCurrentPage(1)
    setSearchTerm(query)
  }

  if(!filteredData || isFetching ) return null

  return (
    <Table data={filteredData} title={"Area Users"} to="/app/addUser" currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} setLimit={setLimit} handleSearch={handleSearch} headers={headers} totalCount={totalUsers} setTotalCount={setTotalUsers} />
  )
}

